import axiosClient from "./AxiosConfig";

export default {
  createGuestUser(guest) {
    return axiosClient.post("/users/guests", guest).then(response => {
      return response.data;
    });
  },
  getUsers() {
    return axiosClient.get("/users").then(response => {
      return response.data;
    });
  },
  getFinancialData(userId) {
    return axiosClient
      .get("/user/" + userId + "/financial-data")
      .then(response => {
        return response.data;
      });
  },
  getBankAccount(userId) {
    return axiosClient
      .get("/user/" + userId + "/bank-account")
      .then(response => {
        return response.data;
      });
  },
  storeBankAccount(userId, bankAccount) {
    return axiosClient
      .post("/user/" + userId + "/bank-account", bankAccount)
      .then(response => {
        return response.data;
      });
  },
  deleteBankAccount(userId) {
    return axiosClient
      .delete("/user/" + userId + "/bank-account")
      .then(response => {
        return response.data;
      });
  }
};
