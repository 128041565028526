<template>
  <div class="mt-16">
    <div style="height: 20px" />
    <v-footer absolute height="60" color="grey--lighten">
      <v-row class="justify-center">
        <v-img
          src="../../assets/scouttools_logo.svg"
          aspect-ratio="1"
          max-width="30"
          contain
          class="mr-3"
        />
        <span class="title font-weight-bold">Scout</span>
        <span class="title font-weight-light">Tools</span>
      </v-row>
      <feedback v-if="feedback && feedbackFixed" />
    </v-footer>
  </div>
</template>

<script>
import Feedback from "@/components/utility/Feedback";
export default {
  name: "Footer",
  components: { Feedback },
  props: {
    feedback: { type: Boolean, default: true },
    feedbackFixed: { type: Boolean, default: true }
  }
};
</script>

<style scoped></style>
