import axiosClient from "./AxiosConfig";

export default {
  login(payload) {
    return axiosClient.post("/login", payload).then(response => {
      return response.data;
    });
  },

  refresh(payload) {
    return axiosClient.post("/refresh", payload).then(response => {
      return response.data;
    });
  },
  getUserInfo() {
    return axiosClient.get("/account/info").then(response => {
      return response.data;
    });
  },
  getUserInfoByRegistrationToken(token) {
    return axiosClient.get("/users/guests/" + token).then(response => {
      return response.data;
    });
  },
  createActivationCode(userId) {
    return axiosClient
      .post("/account/activation/code", { userId: userId })
      .then(response => {
        return response.data;
      });
  },
  checkActivationToken(activationCode) {
    return axiosClient
      .get("/account/activation", {
        params: {
          activationCode: activationCode
        }
      })
      .then(response => {
        return response.data;
      });
  },
  activateAccount(email, password, activationCode, termsAccepted) {
    return axiosClient
      .post(
        "/account/activation",
        {
          email: email,
          password: password,
          activationCode: activationCode
        },
        {
          params: {
            termsAccepted: termsAccepted
          }
        }
      )
      .then(response => {
        return response.data;
      });
  },
  startResetPasswordProcess(email) {
    return axiosClient
      .post("/account/password-reset/token", { email: email })
      .then(response => {
        return response.data;
      });
  },

  resetPassword(token, password) {
    return axiosClient
      .post("/account/password-reset", {
        resetToken: token,
        password: password
      })
      .then(response => {
        return response.data;
      });
  },
  setAccountActive(userId, active) {
    return axiosClient.put("/account/active", {
      userId: userId,
      active: active
    });
  },

  addRoles(userId, roles) {
    return axiosClient.put("/account/roles", {
      userId: userId,
      roles: roles
    });
  },

  removeRoles(userId, roles) {
    return axiosClient.post("/account/roles/deletes", {
      userId: userId,
      roles: roles
    });
  },

  async uploadAvatar(file) {
    let formData = new FormData();
    formData.append("avatar", file);
    return await axiosClient.post("/account/avatar", formData, {
      headers: { "content-type": "multipart/form-data" }
    });
  },

  deleteAvatar() {
    return axiosClient.delete("/account/avatar");
  },

  changeEmail(email) {
    return axiosClient.post("/account/email", {
      email: email
    });
  },

  activateEmail(token) {
    return axiosClient.post("/account/email/activation", {
      token: token
    });
  },

  getEmergencyContacts() {
    return axiosClient.get("/account/emergency-contacts").then(response => {
      return response.data;
    });
  },
  newEmergencyContact(contact) {
    return axiosClient
      .post("/account/emergency-contacts", contact)
      .then(response => {
        return response.data;
      });
  },
  updateEmergencyContact(contact) {
    return axiosClient.put(
      "/account/emergency-contacts/" + contact.id,
      contact
    );
  },
  deleteEmergencyContact(id) {
    return axiosClient.delete("/account/emergency-contacts/" + id);
  }
};
