<template>
  <div>
    <h1>Nutzungsbedingungen</h1>
    <div class="mt-8 mb-8">Stand: 6.11.2020</div>
    <p>
      Im folgenden wird beschrieben, welche Regeln bei der Benutzung von
      Scouttools einzuahlten sind.
    </p>

    <div class="text-h6 font-weight-bold mt-5">Nutzungsvoraussetzungen</div>
    <div>
      Um Scouttools zu benutzen musst du über 18 Jahre sein oder es zusammen mit
      deinen Eltern oder deines Vormundes oder mit deren Einverständis nutzen.
      Insbesondere die Anmeldung zu Aktionen muss in Anwesendheit oder mit dem
      Einverständnis deiner Eltern oder deines Vormundes erfolgen. Wenn du noch
      keine 18 Jahre alt bist, sind Mitteilungen die an dich adressiert sind,
      auch an deine Eltern oder deinen Vormund adressiert. Solltest du aus
      anderen Gründen nicht geschäftsfähig sein, gelten die selben
      Einschränkungen wie für Minderjährige.
    </div>

    <div class="text-h6 font-weight-bold mt-5">Eingabe von Daten</div>
    <div>
      Du sicherst uns zu, dass alle Angaben, die du in Scouttools machst,
      insbesondere bei der Anmeldung zu einer Aktion, wahr, richtig und
      vollständig sind.
    </div>

    <div class="text-h6 font-weight-bold mt-5">
      Schutz von persönlichen Daten
    </div>
    <div>
      Solltest du Leiter im Stamm sein oder eine andere Rolle ausüben, die dich
      zur Einsicht von persönlichen Daten Anderer innerhalb von Scoutttools
      berechtigt, dann liegt es in deiner Verantwortung diese Daten mit zu
      schützen. Die Benutztung und Verarbeitung der persönlichen Daten ist dir
      nur im Rahmen der Ausübung deiner Rolle innerhalb der DPSG Ketsch
      gestattet. Es ist dir untersagt grundlos die Daten zu kopieren und
      außerhalb von Scouttools zu speichern. Die Verantwortung, die persönlichen
      Daten Anderer zu schützen, beinhaltet jegliche Kopie. Dein Zugang zu
      Scouttools und jegliche Kopie ist vor dem Zugriff unbefugter Dritter zu
      schützen. Du verpflichtest dich jegliche Kopien zu zerstören, sobald der
      Verarbeitungszweck nicht mehr gegeben ist und spätestens wenn das
      Zugriffsrecht in Scouttools auf diese Daten nicht mehr besteht.
    </div>

    <div class="text-h6 font-weight-bold mt-5">Erstellung von Inhalten</div>
    <div>
      Falls du in Scouttools die Befugniss hast Inhalte zu erstellen, die
      anderen Nutzern angezeigt werden (z.B. Events und Emails) verpflichtest du
      dich keine Inhalte zu erstellen, die gegen diese Regeln, die guten Sitten
      oder sonst gegen geltendes deutsches Recht verstoßen. Es ist dir
      insbesondere untersagt:
      <ul>
        <li>
          beleidigende oder unwahre Inhalte zu erstellen;
        </li>
        <li>
          Spam über das System an andere Nutzer zu versenden;
        </li>
        <li>
          gesetzlich, insbesondere durch das Urheber- und Markenrecht,
          geschützte Inhalte ohne Berechtigung zu verwenden;
        </li>
        <li>
          Werbung zu betreiben;
        </li>
      </ul>
    </div>
    <div class="text-h6 font-weight-bold mt-5">Haftungsbeschränkung</div>
    <p>
      Wir übernehmen keinerlei Gewähr für die von Benutzern erstellten Inhalte.
      Außerdem übernehmen wir keine Haftung für externe Inhalte.
    </p>
  </div>
</template>

<script>
export default {
  name: "TermsStatement"
};
</script>

<style scoped>
p {
  text-align: justify;
}
</style>
