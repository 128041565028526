<template>
  <v-card outlined width="350">
    <v-toolbar flat color="primary">
      <span class="white--text text-h5">{{ contact.name }}</span>
      <v-spacer />
      <v-btn
        v-if="!noEdit"
        icon
        class="white--text"
        @click="openEmergencyEditDialog(false, contact)"
      >
        <v-icon>
          edit
        </v-icon>
      </v-btn>
      <v-btn
        v-if="!noEdit"
        icon
        class="white--text"
        @click="emergencyContactDeleteDialog = true"
      >
        <v-icon>
          delete
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pl-3">
      <v-list dense>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>person</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Verwandtschaftsverhältnis:</div>
            {{ contact.relationship }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>local_phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="font-weight-light">Telefon:</div>
            <div v-html="getPhoneString()" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-dialog v-model="emergencyContactDeleteDialog" width="400">
      <v-card>
        <v-card-title>
          Löschen?
        </v-card-title>
        <v-card-text>
          Den Notfallkontakt
          <span class="font-weight-bold">{{ contact.name }}</span> wirklich
          löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="emergencyContactDeleteDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="error" @click="deleteContact()">
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { escapeHTML } from "vuetify/es5/util/helpers";

export default {
  name: "EmergencyContact",
  props: {
    contact: Object,
    noEdit: {
      type: Boolean,
      default: false
    },
    openEmergencyEditDialog: Function
  },
  data() {
    return {
      emergencyContactDeleteDialog: false
    };
  },
  methods: {
    ...mapActions("user", ["deleteEmergencyContact"]),
    deleteContact() {
      this.deleteEmergencyContact(this.contact.id);
      this.emergencyContactDeleteDialog = false;
    },
    getPhoneString() {
      let phoneString = escapeHTML(this.contact.phone_1);
      if (this.contact.phone_2) {
        phoneString += "</br>" + escapeHTML(this.contact.phone_2);
      }
      if (this.contact.phone_3) {
        phoneString += "</br>" + escapeHTML(this.contact.phone_3);
      }
      return phoneString;
    }
  }
};
</script>

<style scoped></style>
