<template>
  <v-container fluid fill-height>
    <v-row class="justify-center mx-0">
      <v-card width="600" class="ma-3">
        <v-toolbar flat color="primary">
          <v-toolbar-title class="white--text">
            Passwort zurücksetzen
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          Wähle dein neues Passwort. Anschließend wirst du sofort eingeloggt.
          <v-form
            v-model="formValid"
            @submit.prevent="changePassword()"
            id="reset-password-form"
          >
            <v-text-field
              v-model="newPassword"
              :rules="passwordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              id="password"
              prepend-icon="lock"
              name="password"
              label="Password"
              required
              @click:append="showPassword = !showPassword"
            />
          </v-form>
          <v-alert v-if="error.occured" border="left" color="error">
            {{ error.text }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="newPassword === '' || !formValid"
            class="mr-1 mb-1"
            type="submit"
            color="primary"
            form="reset-password-form"
            >Abschicken</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import AccountAPI from "../../api/AccountAPI";
import { mapActions } from "vuex";

export default {
  name: "ChangePassword",
  data() {
    return {
      newPassword: "",
      formValid: false,
      passwordRules: [
        v => !!v || "Passwort wird benötigt",
        v => /.*[0-9].*/.test(v) || "Passwort muss eine Zahl enthalten. (0-9)",
        v =>
          /.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/.test(v) ||
          "Passwort muss ein Sonderzeichen enthalten \n( !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~)",
        v =>
          (v || "").length >= 8 ||
          "Passwort muss mindestens 8 Zeichen lang sein"
      ],
      showPassword: false,
      error: {
        occured: false,
        text: ""
      }
    };
  },
  methods: {
    ...mapActions("user", ["insertNewTokenPair", "insertUserInfo"]),
    changePassword() {
      let vueI = this;
      AccountAPI.resetPassword(this.$route.params.resetToken, this.newPassword)
        .then(responseData => {
          vueI.insertNewTokenPair(responseData.tokenPair);
          vueI.insertUserInfo(responseData.userInfo);
          vueI.$router.push("/");
        })
        .catch(error => {
          console.log(error);
          vueI.error = {
            occured: true,
            text: error.response.data
          };
        });
    }
  }
};
</script>

<style scoped></style>
