<template>
  <v-container fluid :class="{ 'fill-height': !isMobile }" class="pa-0">
    <v-row no-gutters class="justify-center">
      <v-card
        width="500"
        :outlined="!isMobile"
        :flat="isMobile"
        :loading="loggingIn"
      >
        <v-row no-gutters class="justify-center mt-7 mt-md-5">
          <v-img
            src="../assets/scouttools_logo.svg"
            aspect-ratio="1"
            max-height="64"
            contain
          />
        </v-row>
        <v-row no-gutters class="justify-center mt-2 mb-3">
          <span class="font-weight-bold primary--text text-h4">Scout</span>
          <span class="font-weight-light primary--text text-h4">Tools</span>
        </v-row>
        <v-row no-gutters class="justify-center text-h6 mt-6">
          Anmeldung
        </v-row>
        <v-card-text class="px-6 px-md-6">
          <v-form
            v-model="formValid"
            @submit.prevent="submitLogin()"
            id="check-login-form"
          >
            <v-text-field
              outlined
              v-model="loginInput.email"
              :rules="emailRules"
              prepend-inner-icon="person"
              name="email"
              label="Email"
              type="text"
              required
            />
            <v-text-field
              outlined
              v-model="loginInput.password"
              :rules="passwordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              id="password"
              prepend-inner-icon="lock"
              name="password"
              label="Passwort"
              required
              @click:append="showPassword = !showPassword"
            />
          </v-form>
          <div class="mx-1 mt-2">
            Passwort vergessen?
            <a class="ml-1" @click="$router.push('password-reset')"
              >Passwort-Zurücksetzen</a
            >
          </div>
          <div class="mx-1 mt-1">
            Aktivierungscode?
            <a class="ml-1" @click="$router.push('activation')"
              >Account-Aktivierung</a
            >
          </div>
          <v-row no-gutters class="mt-3 mb-1">
            <v-scale-transition>
              <v-alert
                dense
                dismissible
                type="warning"
                v-if="error"
                class="mb-0"
              >
                {{ error }}
              </v-alert>
            </v-scale-transition>
            <v-spacer />
            <v-btn
              height="40"
              :disabled="
                loggingIn ||
                  loginInput.password === '' ||
                  loginInput.email === ''
              "
              type="submit"
              color="primary"
              form="check-login-form"
              >Login</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AccountAPI from "../api/AccountAPI";
export default {
  data() {
    return {
      formValid: false,
      error: "",
      loginInput: {
        email: "",
        password: ""
      },
      emailRules: [v => !!v || "Bitte gebe deine Account-Email ein."],
      passwordRules: [v => !!v || "Bitte gebe dein Passwort ein."],
      showPassword: false,
      loggingIn: false
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  methods: {
    ...mapActions("user", ["insertNewTokenPair", "insertUserInfo"]),
    submitLogin() {
      this.error = "";
      if (!this.formValid) {
        return;
      }
      this.loggingIn = true;
      AccountAPI.login({
        email: this.loginInput.email.trim(),
        password: this.loginInput.password
      })
        .then(responseData => {
          console.log(responseData);
          this.insertNewTokenPair(responseData.tokenPair);
          this.insertUserInfo(responseData.userInfo);
          this.$router.push("/");
        })
        .catch(error => {
          console.log(error);
          if (error.message === "Network Error") {
            this.error = "Netzwerkfehler";
          } else {
            if (error.response.status === 401) {
              if (error.response.data === "wrong username or password\n") {
                this.error = "Falsche Anmeldedaten";
              } else {
                this.error = "Unerwarteter Fehler";
              }
            } else if (error.response.status === 400) {
              this.error = "Fehler. Anfrage war falsch formatiert";
            } else {
              this.error = "Fehler. Bitte später erneut versucher";
            }
          }
        })
        .finally(() => {
          this.loggingIn = false;
        });
    }
  }
};
</script>
