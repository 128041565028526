<template>
  <v-avatar color="#BDBDBD" :size="size">
    <img v-if="user.accountAvatarId" :src="getAvatarImageUrl" alt="Error" />
    <span v-else class="white--text" :class="{ headline: !size || size > 30 }">
      {{ user.forename ? user.forename.charAt(0) : -"!" }}
    </span>
  </v-avatar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Avatar",
  props: {
    size: String,
    userId: Number
  },
  computed: {
    ...mapGetters("users", { userGetter: "user" }),
    user() {
      return this.userId && this.userId > 0
        ? this.userGetter(this.userId)
        : this.$store.state.user.userInfo;
    },
    getAvatarImageUrl() {
      let baseUrl = process.env.VUE_APP_USER_API_BASE_URL;
      return baseUrl + this.user.accountAvatarId;
    }
  }
};
</script>

<style scoped></style>
