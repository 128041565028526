var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-md-3 mx-md-5 mx-lg-13"},[(!_vm.isMobile)?_c('v-row',{staticClass:"ml-0"},[_c('v-card',{staticClass:"ma-3",attrs:{"outlined":"","width":"240","height":"150"}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Benutzer ")]),_c('v-card-text',[_c('v-row',{staticClass:"justify-center align-center"},[_c('span',{staticClass:"display-2"},[_vm._v(_vm._s(_vm.users.length))])])],1)],1),_c('v-card',{staticClass:"ma-3",attrs:{"outlined":"","width":"240","height":"150"}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Aktive Accounts ")]),_c('v-card-text',[_c('v-row',{staticClass:"justify-center align-center"},[_c('span',{staticClass:"display-2"},[_vm._v(_vm._s(_vm.activeAccountNumber))])])],1)],1),_c('v-card',{staticClass:"ma-3",attrs:{"outlined":"","width":"240","height":"150"}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Offene Aktivierungen ")]),_c('v-card-text',[_c('v-row',{staticClass:"justify-center align-center"},[_c('span',{staticClass:"display-2"},[_vm._v(_vm._s(_vm.openActivation))])])],1)],1),_c('user-analysis-report',{staticClass:"ma-3"})],1):_vm._e(),_c('v-card',{staticClass:"my-3 mx-md-3",attrs:{"outlined":!_vm.isMobile,"flat":_vm.isMobile}},[_c('v-card-title',[_c('span',[_vm._v("Benutzerverwaltung")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.exportDialog = true}}},[_c('v-icon',[_vm._v(" publish ")])],1),_c('v-btn',{staticClass:"mr-3",attrs:{"dark":"","color":"primary"},on:{"click":function($event){return _vm.refreshUsers()}}},[_c('v-icon',[_vm._v(" sync ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-6",attrs:{"width":"180","color":"primary","dark":""}},Object.assign({}, tooltip, menu)),[_vm._v(_vm._s(_vm.selectedFilter))])]}}],null,true)},[_c('span',[_vm._v("Filtere nach Mitglieds-Rolle")])])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.selectedFilter = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-text-field',{staticClass:"pt-0 mt-md-0 mt-5",attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.searchedUsers,"item-class":_vm.highlightOwnEntry},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': item.id === _vm.userInfo.id }},[_vm._v(_vm._s(item.forename + " " + item.surname))])]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': item.id === _vm.userInfo.id }},[_vm._v(_vm._s(_vm.formatBirthday(item.birthday)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.userInfo.id)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push('/personal')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" person ")])],1):[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openUserDetailDialog(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" info ")])],1),(item.accountEmail)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setAccountActive({
                userId: item.id,
                active: !item.accountActive
              })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.accountActive ? "lock" : "lock_open")+" ")])],1):_vm._e(),(item.accountEmail)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":!item.accountActive}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" person_add ")])],1)]}}],null,true)},[_c('span',[_vm._v("Zugriffsrechte Rolle hinzufügen")])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"value":"Admin","hide-details":"","label":"Admin"},on:{"change":function($event){return _vm.pressRoleCheckbox(item.roles, item.id, 'Admin')}},model:{value:(item.roles),callback:function ($$v) {_vm.$set(item, "roles", $$v)},expression:"item.roles"}})],1)],1)],1):_vm._e()]]}},{key:"item.account",fn:function(ref){
              var item = ref.item;
return [(item.accountEmail)?_c('div',[(!item.accountActive)?_c('span',{staticClass:"font-weight-light"},[_vm._v("Inaktiver")]):_vm._e(),_vm._v(" Account: "),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.accountEmail))])]):_vm._e(),(!item.accountEmail && !item.accountActivationCode)?_c('div',[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.createActivationCode(item.id)}}},[_c('v-icon',[_vm._v("add")]),_vm._v("Aktivierungscode ")],1)],1):_vm._e(),(item.accountActivationCode)?_c('div',[_vm._v(" Aktivierungscode: "),_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-family":"monospace, monospace"}},[_vm._v(" "+_vm._s(_vm.formattedActivationCode(item.accountActivationCode))+" "),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.shareActivationCode(item.accountActivationCode)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" share ")])],1)],1)]):_vm._e()]}},{key:"item.activities",fn:function(ref){
              var item = ref.item;
return [(!item.activities)?_c('div',[_vm._v(" Keine Aktivitäten ")]):_c('div',_vm._l((_vm.filterActiveActivities(item.activities)),function(activity){return _c('v-chip',{key:activity.nami_id,staticClass:"mr-1 my-1",attrs:{"color":_vm.nameToColor(activity.name)}},[_vm._v(_vm._s(activity.name))])}),1)]}},{key:"item.roles",fn:function(ref){
              var item = ref.item;
return [(
            item.accountActive &&
              item.roles.filter(function (role) { return role === 'Admin'; }).length > 0
          )?_c('div',[_c('v-icon',[_vm._v(" add ")]),_vm._l((item.roles.filter(function (role) { return role === 'Admin'; })),function(role){return _c('v-chip',{key:role,staticClass:"mr-1 my-1",attrs:{"color":_vm.nameToColor(role)}},[_vm._v(_vm._s(role))])})],2):_vm._e()]}}])}),_c('user-detail-dialog',{ref:"userDetailDialog"})],1),_c('csv-export-dialog',{attrs:{"data":_vm.users,"headers":_vm.exportHeaders},model:{value:(_vm.exportDialog),callback:function ($$v) {_vm.exportDialog=$$v},expression:"exportDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }