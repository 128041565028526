import Parser from "@/utils/Parser";

export default {
  exportCSVFile(headers, items, fileTitle) {
    items.unshift(this.createHeaderItem(headers));

    let csv = this.arrayToCSV(items, headers);

    let exportedFilenmae = fileTitle || "export.csv";

    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },

  attendeeHeaders() {
    return [
      {
        propName: "name",
        title: "Name"
      },
      {
        propName: "birthday",
        title: "Geburtsdatum"
      },
      {
        propName: "address",
        title: "Anschrift"
      }
    ];
  },

  exportAttendeeList(attendees, fileTitle) {
    let copyied = JSON.parse(JSON.stringify(attendees)); //create deep copy
    copyied.forEach(attendee => {
      attendee.birthday = Parser.parseDate(new Date(attendee.birthday));
    });
    this.exportCSVFile(this.attendeeHeaders(), copyied, fileTitle);
  },

  createHeaderItem(headers) {
    let headerItem = {};
    headers.forEach(header => (headerItem[header.propName] = header.title));
    return headerItem;
  },

  arrayToCSV(objArray, headers) {
    return objArray
      .map(el =>
        headers
          .map(header => {
            console.log(el[header.propName]);
            return el[header.propName].includes(",")
              ? '"' + el[header.propName] + '"'
              : el[header.propName];
          })
          .join(",")
      )
      .join("\r\n");
  }
};
