import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import user from "./modules/user";
import settings from "./modules/settings";
import users from "./modules/users";
import pwa from "./modules/pwa";
import events from "./modules/events";
import snackbar from "@/store/modules/snackbar";
import invoices from "@/store/modules/invoices";
import financial from "@/store/modules/financial";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["user", "settings", "users", "events", "invoices", "financial"]
});

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    settings,
    users,
    events,
    invoices,
    financial,
    pwa,
    snackbar
  },
  plugins: [vuexLocal.plugin]
});

export default store;
