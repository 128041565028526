import axiosClient from "./AxiosConfig";

export default {
  newFeedback(payload) {
    let payloadString = JSON.stringify(payload);
    return axiosClient.post("/feedback", payloadString, {
      headers: { "Content-type": "application/json" }
    });
  },

  getFeeback() {
    return axiosClient.get("/feedback").then(response => {
      return response.data;
    });
  }
};
