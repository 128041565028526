import MembersAPI from "../../api/UsersAPI";
import AccountAPI from "@/api/AccountAPI";
import Vue from "vue";

const updateInterval = 30 * 1000; //30 seconds

export default {
  namespaced: true,

  state: {
    users: [],
    users_last_updated: 0
  },

  mutations: {
    updateUsers(state, members) {
      state.users = members;
      state.users_last_updated = Date.now();
    },
    setActivationCode(state, payload) {
      let index = state.users.map(user => user.id).indexOf(payload.userId);
      Vue.set(state.users, index, {
        ...state.users[index],
        accountActivationCode: payload.activationCode
      });
    },
    setAccountActive(state, payload) {
      let index = state.users.map(user => user.id).indexOf(payload.userId);
      state.users[index].accountActive = payload.active;
    }
  },

  actions: {
    refreshUsers({ commit, state, dispatch }) {
      if (
        !state.users ||
        !state.users_last_updated ||
        Date.now() - state.users_last_updated > updateInterval
      ) {
        return MembersAPI.getUsers()
          .then(members => {
            commit("updateUsers", members);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: error.response ? error.response.data : "Unbekannter Fehler",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    createActivationCode({ commit, dispatch }, userId) {
      AccountAPI.createActivationCode(userId)
        .then(response => {
          commit("setActivationCode", {
            userId: userId,
            activationCode: response.activationCode
          });
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: error.response ? error.response.data : "Unbekannter Fehler",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    setAccountActive({ commit, dispatch }, payload) {
      AccountAPI.setAccountActive(payload.userId, payload.active)
        .then(() => {
          commit("setAccountActive", payload);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: error.response ? error.response.data : "Unbekannter Fehler",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    }
  },

  getters: {
    user: state => id => {
      return state.users.find(user => user.id === id);
    },
    treasurers: state => {
      return state.users.filter(
        user =>
          user.activities &&
          user.activities.find(
            activity => activity.active && activity.name === "Kassierer"
          )
      );
    }
  }
};
