export default {
  namespaced: true,

  state: {
    updateAvailable: false,
    registration: null,
    installable: false,
    installationPrompt: null
  },

  mutations: {
    setUpdateAvailable(state, update) {
      state.updateAvailable = update;
    },
    setRegistration(state, registration) {
      state.registration = registration;
    },
    setInstallable(state, prompt) {
      state.installable = true;
      state.installationPrompt = prompt;
    },
    setNotInstallable(state) {
      state.installable = false;
      state.installationPrompt = null;
    }
  },

  actions: {
    setNotInstallable({ commit }) {
      commit("setNotInstallable");
    },
    setInstallable({ commit }, prompt) {
      commit("setInstallable", prompt);
    },
    installApp({ commit, state, dispatch }) {
      state.installationPrompt.prompt();
      state.installationPrompt.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          dispatch(
            "snackbar/showSnackbar",
            { text: "App installiert" },
            { root: true }
          );
        } else {
          commit("setNotInstallable");
        }
      });
    },
    setUpdateAvailable({ commit, dispatch }, registration) {
      console.log("setting update available");
      commit("setUpdateAvailable", true);
      commit("setRegistration", registration);
      let store = this;
      let payload = {
        text: "Update verfügbar",
        actions: [
          {
            text: "Update",
            function: () => {
              store.dispatch("pwa/refreshApp", null, { root: true });
            }
          }
        ]
      };
      dispatch("snackbar/showSnackbar", payload, { root: true });
    },
    refreshApp({ commit, state }) {
      console.log("refreshing app");
      if (!state.registration || !state.registration.waiting) {
        return;
      }

      state.registration.waiting.postMessage("skipWaiting");
      commit("setUpdateAvailable", false);
      commit("setRegistration", null);
    }
  }
};
