import FinancialAPI from "@/api/FinancialAPI";
import { objectDifference } from "@/utils/Diff";
import Parser from "@/utils/Parser";

const updateInterval = 30 * 1000; //30 seconds

export default {
  namespaced: true,

  state: {
    accounts: [],
    accounts_last_updated: 0,
    bookings: [],
    bookings_last_updated: 0,
    bankbookings: [],
    bankbookings_last_updated: 0,
    auditlogs: [],
    auditlogs_realdiff: [],
    auditlogs_last_updated: 0,
    settlements: [],
    settlements_last_updated: 0,
    actions: {
      sepa_update: {
        updating: false,
        result: null,
        status: null
      }
    }
  },

  mutations: {
    updateSettlements(state, settlements) {
      state.settlements = settlements;
      state.settlements_last_updated = Date.now();
    },
    updateSettlement(state, updatedSettlement) {
      let id = state.settlements.findIndex(
        settlement => settlement.id === updatedSettlement.id
      );
      Object.assign(state.settlements[id], updatedSettlement);
    },
    addSettlement(state, settlement) {
      state.settlements.push(settlement);
    },
    updateAuditlogs(state, auditlogs) {
      state.auditlogs = auditlogs;
      state.auditlogs_last_updated = Date.now();
      let logs = {};
      state.auditlogs_realdiff = [];

      // Sort array to calculate deltas
      state.auditlogs.sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
      );

      state.auditlogs.forEach(log_org => {
        const log = { ...log_org };
        if (log.action == "Erstellt") {
          if (!logs[log.obj]) {
            logs[log.obj] = {};
          }
          logs[log.obj][log.obj_id] = [
            {
              log: log,
              value: log.value
            }
          ];
          log.delta = log.value;
        } else if (log.action == "Gelöscht") {
          if (!logs[log.obj] || !logs[log.obj][log.obj_id]) {
            if (!logs[log.obj]) {
              logs[log.obj] = {};
            }
            logs[log.obj][log.obj_id] = [{ value: "{}" }];
          }
          logs[log.obj][log.obj_id].push({
            log: log,
            value: log.value
          });
          log.delta = log.value;
        } else if (log.action == "Geändert") {
          if (!logs[log.obj] || !logs[log.obj][log.obj_id]) {
            if (!logs[log.obj]) {
              logs[log.obj] = {};
            }
            logs[log.obj][log.obj_id] = [{ value: "{}" }];
          }
          const lastvalue =
            logs[log.obj][log.obj_id][logs[log.obj][log.obj_id].length - 1]
              .value;
          const lastobj = JSON.parse(lastvalue);
          const obj = JSON.parse(log.value);
          logs[log.obj][log.obj_id].push({
            log: log,
            value: log.value
          });
          log.delta = JSON.stringify(objectDifference(lastobj, obj));
        }
        state.auditlogs_realdiff.push(log);
      });
    },

    updateSepaState(state, data) {
      state.actions.sepa_update.updating = false;
      state.actions.sepa_update.result = data;
      if (data !== null) {
        state.actions.sepa_update.status = "Done";
      } else {
        state.actions.sepa_update.status = "Error, aborted";
      }
    },
    updateAccounts(state, accounts) {
      state.accounts = accounts;
      state.accounts_last_updated = Date.now();
    },
    deleteAccount(state, id) {
      let index = state.accounts.findIndex(account => account.id === id);
      state.accounts.splice(index, 1);
    },
    addAccount(state, account) {
      state.accounts.push(account);
      state.accounts.forEach(elem => {
        if (elem != account) {
          account.order = account.order + 1;
        }
      });
    },
    updateAccount(state, updatedAccount) {
      let id = state.accounts.findIndex(
        account => account.id === updatedAccount.id
      );
      Object.assign(state.accounts[id], updatedAccount);
    },
    patchAccount(state, patchAccount) {
      let id = state.accounts.findIndex(
        account => account.id === patchAccount.id
      );
      Object.assign(state.accounts[id], {
        ...state.accounts[id],
        ...patchAccount
      });
    },

    deleteBooking(state, id) {
      let index = state.bookings.findIndex(booking => booking.id === id);
      state.bookings.splice(index, 1);
    },
    updateBookings(state, bookings) {
      state.bookings = bookings;
      state.bookings_last_updated = Date.now();
    },
    addBooking(state, booking) {
      state.bookings.push(booking);
    },
    addBookings(state, bookings) {
      bookings.forEach(booking => {
        state.bookings.push(booking);
      });
    },
    updateBooking(state, updatedBooking) {
      let id = state.bookings.findIndex(
        booking => booking.id === updatedBooking.id
      );
      Object.assign(state.bookings[id], updatedBooking);
    },

    updateBankBookings(state, bankbookings) {
      state.bankbookings = bankbookings;
      state.bankbookings.forEach(bankbooking => {
        bankbooking.amount_booked_percent = Parser.DEstringToNumber(
          bankbooking.amount_booked_percent
        );
      });
      state.bankbookings_last_updated = Date.now();
    }
  },

  actions: {
    fetchSettlements({ state, commit, dispatch }, force = false) {
      if (
        force ||
        !state.settlements ||
        !state.settlements_last_updated ||
        Date.now() - state.settlements_last_updated > updateInterval
      ) {
        FinancialAPI.getSettlements()
          .then(settlements => {
            commit("updateSettlements", settlements);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: "Abrechnungen konnten nicht aktualisiert werden",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    updateSettlement({ commit, dispatch }, settlementSubmission) {
      FinancialAPI.updateSettlement(settlementSubmission)
        .then(settlement => {
          commit("updateSettlement", settlement);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Abrechnung konnte nicht geändert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    submitSettlement({ commit, dispatch }, settlementSubmission) {
      FinancialAPI.submitSettlement(settlementSubmission)
        .then(settlement => {
          commit("addSettlement", settlement);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Abrechnung konnte nicht erstellt werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    fetchAuditlogs({ state, commit, dispatch }, force = false) {
      if (
        force ||
        !state.auditlogs ||
        !state.auditlogs_last_updated ||
        Date.now() - state.auditlogs_last_updated > updateInterval
      ) {
        FinancialAPI.getAuditlogs()
          .then(auditlogs => {
            commit("updateAuditlogs", auditlogs);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: "Auditlogs konnten nicht aktualisiert werden",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    fetchAccounts({ state, commit, dispatch }, force = false) {
      if (
        force ||
        !state.accounts ||
        !state.accounts_last_updated ||
        Date.now() - state.accounts_last_updated > updateInterval
      ) {
        FinancialAPI.getAccounts()
          .then(accounts => {
            commit("updateAccounts", accounts);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: "Konten konnten nicht aktualisiert werden",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    deleteAccount({ commit, dispatch }, id) {
      FinancialAPI.deleteAccount(id)
        .then(() => {
          commit("deleteAccount", id);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Konto konnte nicht gelöscht werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    updateAccount({ commit, dispatch }, accountSubmission) {
      FinancialAPI.updateAccount(accountSubmission)
        .then(account => {
          commit("updateAccount", account);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Konto konnte nicht geändert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    patchAccount({ commit, dispatch }, accountSubmission) {
      FinancialAPI.patchAccount(accountSubmission)
        .then(() => {
          commit("patchAccount", accountSubmission);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Konto konnte nicht teilw. aktualisiert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    submitAccount({ commit, dispatch }, accountSubmission) {
      FinancialAPI.submitAccount(accountSubmission)
        .then(account => {
          commit("addAccount", account);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Konto konnte nicht erstellt werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    fetchBookings({ state, commit, dispatch }, force = false) {
      if (
        force ||
        !state.bookings ||
        !state.bookings_last_updated ||
        Date.now() - state.bookings_last_updated > updateInterval
      ) {
        FinancialAPI.getBookings()
          .then(bookings => {
            commit("updateBookings", bookings);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: "Buchungen konnten nicht aktualisiert werden",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    deleteBooking({ commit, dispatch }, id) {
      FinancialAPI.deleteBooking(id)
        .then(() => {
          commit("deleteBooking", id);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Buchung konnte nicht gelöscht werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    updateBooking({ commit, dispatch }, bookingSubmission) {
      console.log("Store", bookingSubmission, bookingSubmission.bank_booking);
      FinancialAPI.updateBooking(bookingSubmission)
        .then(booking => {
          commit("updateBooking", booking);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Buchung konnte nicht geändert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    submitBooking({ commit, dispatch }, bookingSubmission) {
      FinancialAPI.submitBooking(bookingSubmission)
        .then(booking => {
          commit("addBooking", booking);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Buchung konnte nicht erstellt werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    duplicateBookings({ dispatch }, ids) {
      FinancialAPI.postBookingDuplicate(ids)
        .then(() => {
          dispatch("fetchBookings", true);
          dispatch("fetchAccounts", true);
          dispatch("fetchBankBooking", true);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Buchung konnte nicht dupliziert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    deleteBookings({ dispatch }, ids) {
      FinancialAPI.postBookingBatchDelete(ids)
        .then(() => {
          dispatch("fetchBookings", true);
          dispatch("fetchAccounts", true);
          dispatch("fetchBankBooking", true);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Buchung konnte nicht gelöscht werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    bookBookings({ dispatch }, data) {
      FinancialAPI.postBookingBatchBookAccount(data)
        .then(() => {
          dispatch("fetchBookings", true);
          dispatch("fetchAccounts", true);
          dispatch("fetchBankBooking", true);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Buchung konnte nicht verbucht werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },

    fetchBankBookings({ state, commit, dispatch }, force = false) {
      if (
        force ||
        !state.bankbookings ||
        !state.bankbookings_last_updated ||
        Date.now() - state.bankbookings_last_updated > updateInterval
      ) {
        FinancialAPI.getBankBookings()
          .then(bankbookings => {
            commit("updateBankBookings", bankbookings);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: "Bank-Buchungen konnten nicht aktualisiert werden",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    requestSepaUpdate({ state, commit }) {
      state.actions.sepa_update.updating = true;
      FinancialAPI.getBankSepaUpdate()
        .then(result => {
          commit("updateSepaState", result);
        })
        .catch(error => {
          console.log(error);
          commit("updateSepaState", null);
        });
    },
    bankBookingCreateBooking({ commit, dispatch }, action_data) {
      FinancialAPI.postBankBookingCreateBooking(action_data)
        .then(data => {
          commit("addBookings", data.message);
          dispatch("fetchAccounts", true);
          dispatch("fetchBankBooking", true);
          let snackbar = {
            text: "Buchungen wurden erstellt",
            color: "success"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Buchung konnte nicht erstellt werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    }
  },

  getters: {
    settlement: state => id => {
      return state.settlements.find(settlement => settlement.id === id);
    },
    account: state => id => {
      return state.accounts.find(account => account.id === id);
    },
    booking: state => id => {
      return state.bookings.find(bookings => bookings.id === id);
    },
    bankbooking: state => id => {
      return state.bankbookings.find(bankbookings => bankbookings.id === id);
    },
    actions_sepa_update_updating: state => () => {
      return state.actions.sepa_update.updating;
    },
    auditlogs_realdiff: state => () => {
      return state.auditlogs_realdiff;
    }
  }
};
