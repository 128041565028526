<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="justify-center align-baseline mx-0 px-0">
      <v-col class="col-12 col-md-10 col-lg-8">
        <v-row class="justify-center justify-md-start">
          <v-img
            src="../assets/scouttools_logo.svg"
            aspect-ratio="1"
            max-width="45"
            contain
            class="mb-1 mr-2 ml-4"
          />
          <div class="mb-8 mt-8">
            <span class="display-2 font-weight-bold primary--text">Scout</span>
            <span class="display-2 font-weight-light primary--text">Tools</span>
          </div>
        </v-row>
        <v-stepper v-model="e6" vertical :class="{ 'elevation-0': isMobile }">
          <v-stepper-step :complete="e6 > 1" step="1">
            Aktivierung
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card class="mb-4" max-width="600" flat>
              <v-card-subtitle class="pb-1">
                Aktiviere deinen Account und gebe dazu deinen Aktivierungscode
                ein:
              </v-card-subtitle>
              <v-card-text>
                <v-row class="mx-n2">
                  <v-col class="col-md-9 col-12 pa-0 pt-3 px-2">
                    <v-form
                      @submit.prevent="sendActivationCode()"
                      id="activation-code-form"
                    >
                      <v-text-field
                        hide-details
                        solo
                        v-model="activationCodeInput"
                        placeholder="#### #### #### #### ####"
                        v-mask="mask"
                        maxlength="24"
                        style="font-family: monospace, monospace"
                      />
                    </v-form>
                  </v-col>
                  <v-col class="col-md-3 col-12 pa-0 pt-3 px-2">
                    <v-btn
                      height="48"
                      color="primary"
                      :disabled="activationCodeInput.length !== 24"
                      type="submit"
                      form="activation-code-form"
                    >
                      Abschicken
                    </v-btn>
                  </v-col>
                </v-row>
                <v-scale-transition>
                  <v-alert
                    v-if="error"
                    type="warning"
                    dismissible
                    class="mb-0 mt-3"
                  >
                    {{ error }}
                  </v-alert>
                </v-scale-transition>
                <div class="mt-3">
                  Du hast bereits einen Account?
                  <a class="ml-1" @click="toLogin()">Weiter zum Login</a>
                </div>
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2"
            >Account-Einrichtung</v-stepper-step
          >

          <v-stepper-content step="2">
            <v-card flat>
              <v-card-title>
                Herzlich Willkommen <b class="ml-1">{{ name }}</b
                >!
              </v-card-title>
              <v-card-text>
                <div>
                  Um die Einrichtung deines Accounts abzuschließen, musst du
                  noch deine Zugangsdaten festlegen.
                  <div>
                    Zur Identifikation wird eine Email und ein Passwort
                    benötigt:
                  </div>
                </div>
                <div v-if="email">
                  <div class="mt-2">
                    Hinterlegte Email: <b>{{ email }}</b>
                  </div>

                  <v-checkbox v-model="useEmail">
                    <template v-slot:label>
                      <span style="font-size: 0.875rem; font-weight: 400;">
                        Diese Email für den Login verwenden
                      </span>
                    </template>
                  </v-checkbox>
                </div>
                <v-form ref="activationForm">
                  <v-text-field
                    v-if="emailInputNeeded"
                    style="max-width: 500px"
                    placeholder="Email"
                    v-model="emailInput"
                    :rules="[
                      v => (!!v && emailInputNeeded) || 'Email wird benötigt',
                      v =>
                        (/.+@.+\..+/.test(v) && emailInputNeeded) ||
                        'Bitte gebe eine gültige Email ein'
                    ]"
                    prepend-icon="email"
                    name="email"
                    type="text"
                  />
                  <v-text-field
                    style="max-width: 500px"
                    placeholder="Passwort"
                    v-model="passwordInput"
                    :rules="[
                      v => !!v || 'Passwort wird benötigt',
                      v =>
                        /.*[0-9].*/.test(v) ||
                        'Passwort muss eine Zahl enthalten (0-9)',
                      v =>
                        /.*[ '!#$%&()*+,-./:;<=>?@[\]^_`{|}~].*/.test(v) ||
                        'Passwort muss ein Sonderzeichen enthalten \n( !#$%&()*+,-./:;<=>?@[\\]^_`{|}~)',
                      v =>
                        (v || '').length >= 8 ||
                        'Passwort muss mindestens 8 Zeichen lang sein'
                    ]"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    id="password"
                    prepend-icon="lock"
                    name="password"
                    required
                    @click:append="showPassword = !showPassword"
                  />
                  <v-checkbox
                    v-model="termsAccepted"
                    :rules="[
                      v => !!v || 'Nutzungsbedingungen müssen akzeptiert werden'
                    ]"
                  >
                    <template v-slot:label>
                      <span style="font-size: 0.875rem; font-weight: 400;">
                        Ich stimme hiermit den
                        <a @click="termsStatementDialog = true"
                          >Nutzungsbedingungen</a
                        >
                        von Scouttools zu.
                      </span>
                    </template>
                  </v-checkbox>
                  <div class="ml-8 my-4" style="max-width: 500px">
                    Wenn du mehr darüber erfahren möchtest, wie Scouttools
                    personenbezogene Daten verarbeitet, lies bitte die
                    <a @click="privacyStatementDialog = true"
                      >Datenschutzerklärung</a
                    >
                    von Scouttools.
                  </div>
                </v-form>
                <v-alert v-if="error" type="warning" dismissible class="mb-0">
                  {{ error }}
                </v-alert>
              </v-card-text>
              <v-card-actions class="ml-2">
                <v-btn color="primary" @click="activateAccount()"
                  >Account erstellen</v-btn
                >
                <v-btn text @click="e6 = 1">Abbruch</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3"
            >Einführung</v-stepper-step
          >

          <v-stepper-content step="3">
            <v-card flat class="mb-3">
              <v-card-title>
                Dein Account wurde erfolgreich eingerichtet!
              </v-card-title>
              <v-card-text>
                Über deinen Account kannst du einsehen, welche Informationen
                über dich von der DPSG Ketsch über dich gespeichert werden.
                Darüber hinaus bietet er dir Möglichkeiten auf angebotene
                digitale Dienste zuzugreifen.
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" to="/">Weiter zur Übersicht</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
    <v-dialog v-model="privacyStatementDialog" width="1000">
      <v-card>
        <v-card-text class="pt-6">
          <privacy-statement />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="privacyStatementDialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="termsStatementDialog" width="1000">
      <v-card>
        <v-card-text class="pt-6">
          <terms-statement />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="termsStatementDialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import AccountAPI from "../api/AccountAPI";
import PrivacyStatement from "@/components/legal/PrivacyStatement";
import TermsStatement from "@/components/legal/TermsStatement";

export default {
  name: "Activation",
  components: { TermsStatement, PrivacyStatement },
  directives: {
    mask
  },
  data() {
    return {
      e6: 1,
      activationCodeInput: "",
      name: "",
      email: "",
      error: "",
      mask: "XXXX XXXX XXXX XXXX XXXX",
      useEmail: true,
      emailInput: "",
      passwordInput: "",
      showPassword: false,
      privacyStatementDialog: false,
      termsStatementDialog: false,
      termsAccepted: false
    };
  },
  computed: {
    emailInputNeeded() {
      return this.email === "" || !this.useEmail;
    },
    activationToken() {
      return this.activationCodeInput.split(" ").join("");
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("user", ["insertNewTokenPair", "insertUserInfo", "doLogout"]),
    toLogin() {
      this.$router.push("landing");
    },
    sendActivationCode() {
      this.error = "";
      AccountAPI.checkActivationToken(this.activationToken)
        .then(response => {
          this.name = response.name;
          this.email = response.email;
          this.e6 = 2;
        })
        .catch(error => {
          if (error.message === "Network Error") {
            this.error = "Netzwerkfehler";
          } else if (
            error.response.data.includes("activation token not found")
          ) {
            this.error = "Aktivierungscode ungültig";
          } else {
            console.log(error);
          }
        });
    },
    activateAccount() {
      if (!this.$refs.activationForm.validate()) {
        return;
      }
      let email = "";
      if (this.email && this.useEmail) {
        email = this.email;
      } else {
        if (!this.emailInput) {
          return;
        }
        email = this.emailInput;
      }
      let password = "";
      if (!this.passwordInput) {
        return;
      }
      password = this.passwordInput;
      AccountAPI.activateAccount(
        email,
        password,
        this.activationToken,
        this.termsAccepted
      )
        .then(responseData => {
          this.insertNewTokenPair(responseData.tokenPair);
          this.insertUserInfo(responseData.userInfo);
          this.e6 = 3;
        })
        .catch(error => {
          if (error.message === "Network Error") {
            this.doLogout();
            this.error = "Netzwerkfehler";
          } else if (
            error.response.data.includes("email address already in use")
          ) {
            this.useEmail = false;
            this.error =
              "Email wird bereits verwendet. Bitte wähle eine andere Email";
          } else {
            console.log(error);
          }
        });
    }
  }
};
</script>

<style></style>
