import axiosClient from "./AxiosConfig";

const formatEvent = function(event) {
  let startDateTime = new Date(event.startDate + " " + event.startTime);
  let endDateTime = new Date(event.endDate + " " + event.endTime);
  let registrationDeadline = new Date(
    event.registrationInformation.registrationDeadline
  );
  let formattedEvent = JSON.parse(JSON.stringify(event));
  delete formattedEvent.startDate;
  delete formattedEvent.endDate;
  formattedEvent.startTime = startDateTime;
  formattedEvent.endTime = endDateTime;
  formattedEvent.registrationInformation.registrationDeadline = registrationDeadline;
  return formattedEvent;
};

const parseEvent = function(event) {
  let parseDate = function(date) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  };
  let parseTime = function(date) {
    return (
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2)
    );
  };
  let start = new Date(event.startTime);
  let end = new Date(event.endTime);
  let registrationDeadline = new Date(
    event.registrationInformation.registrationDeadline
  );
  event.startDate = parseDate(start);
  event.endDate = parseDate(end);
  event.registrationInformation.registrationDeadline = parseDate(
    registrationDeadline
  );
  event.startTime = parseTime(start);
  event.endTime = parseTime(end);
  return event;
};

export default {
  getEvents() {
    return axiosClient.get("/events").then(response => {
      return response.data.map(event => {
        return parseEvent(event);
      });
    });
  },
  getEventByToken(token) {
    return axiosClient.get("/event/" + token).then(response => {
      return parseEvent(response.data);
    });
  },
  getEventByRegistrationToken(token) {
    return axiosClient
      .get("/users/guests/" + token + "/event")
      .then(response => {
        return parseEvent(response.data);
      });
  },
  createEvent(event) {
    let newEvent = formatEvent(event);
    return axiosClient.post("/events", newEvent).then(response => {
      return response.data;
    });
  },
  updateEvent(event) {
    let updatedEvent = formatEvent(event);
    return axiosClient.put("/events/" + event.id, updatedEvent);
  },
  deleteEvent(id) {
    return axiosClient.delete("/events/" + id);
  },
  getRegistrations() {
    return axiosClient.get("/registrations").then(response => {
      return response.data;
    });
  },
  getRegistrationByToken(token) {
    return axiosClient.get("/registration/" + token).then(response => {
      return response.data;
    });
  },
  newRegistration(registration) {
    return axiosClient.post("/registrations", registration).then(response => {
      return response.data;
    });
  },
  updateRegistration(registration) {
    return axiosClient.put("/registrations/" + registration.id, registration);
  },
  cancelRegistration(id) {
    return axiosClient.delete("/registrations/" + id);
  },
  getEventsOperationInformation() {
    return axiosClient.get("/events/operation").then(response => {
      return response.data;
    });
  },
  startNewGuestRegistration(email, token) {
    return axiosClient.post("/registrations/guest", {
      email: email,
      token: token
    });
  }
};
