<template>
  <div>
    <leiter-dashboard v-if="isAdmin || isStavo || isLeiter" />
    <div v-else>
      <div class="ma-3">
        Hier werden in Zukunft Neuigkeiten angezeigt und wichtige Tools verlinkt
        werden
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeiterDashboard from "./dashboard/LeiterDashboard";

export default {
  name: "Dashboard",
  components: {
    "leiter-dashboard": LeiterDashboard
  },
  computed: {
    ...mapGetters("user", ["isAdmin", "isLeiter", "isStavo"])
  }
};
</script>

<style scoped></style>
