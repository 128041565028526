export default {
  parseTelefonNumber(telefon_1, telefon_2, telefon_3) {
    let telefonGesamt = "";
    if (telefon_1) {
      telefonGesamt = telefon_1;
    }
    if (telefon_2) {
      if (telefonGesamt !== "") {
        telefonGesamt = telefonGesamt + "; ";
      }
      telefonGesamt = telefonGesamt + telefon_2;
    }
    if (telefon_3) {
      if (telefonGesamt !== "") {
        telefonGesamt = telefonGesamt + "; ";
      }
      telefonGesamt = telefonGesamt + telefon_3;
    }
    return telefonGesamt;
  },

  parseEmail(email, email_vertretungsberechtigter) {
    let emailGesamt = "";
    if (email) {
      emailGesamt = email;
    }
    if (email_vertretungsberechtigter) {
      if (emailGesamt) {
        emailGesamt =
          emailGesamt +
          "; Vertretungsberechtigter: " +
          email_vertretungsberechtigter;
      } else {
        emailGesamt =
          "Vertretungsberechtigter: " + email_vertretungsberechtigter;
      }
    }
    return emailGesamt;
  },
  parseDate(date) {
    return (
      ("0" + date.getDate()).slice(-2) +
      "." +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "." +
      date.getFullYear()
    );
  },
  parseDateTime(datetime) {
    return (
      this.parseDate(datetime) +
      " " +
      ("0" + datetime.getHours()).slice(-2) +
      ":" +
      ("0" + datetime.getMinutes()).slice(-2)
    );
  },
  parseLocation(location) {
    let locationString =
      location.name +
      ", " +
      location.street +
      " " +
      location.postalCode +
      " " +
      location.city;
    if (location.country !== "Deutschland") {
      locationString += " " + location.country;
    }
    return locationString;
  },

  formatActivationCode(activationCode) {
    let result = "";
    result += activationCode.substring(0, 4) + " ";
    result += activationCode.substring(4, 8) + " ";
    result += activationCode.substring(8, 12) + " ";
    result += activationCode.substring(12, 16) + " ";
    result += activationCode.substring(16, 20);
    return result;
  },

  nameToColor(name) {
    switch (name) {
      case "Wölfling":
        return "orange darken-2";
      case "Jungpfadfinder":
        return "light-blue darken-1";
      case "Pfadfinder":
        return "green darken-2";
      case "Rover":
        return "red darken-2";
      case "Wölflingsleiter":
        return "blue-grey lighten-1";
      case "Jungpfadfinderleiter":
        return "blue-grey lighten-1";
      case "Pfadfinderleiter":
        return "blue-grey lighten-1";
      case "Roverleiter":
        return "blue-grey lighten-1";
      case "StaVo":
        return "amber lighten-1";
      default:
        return "blue lighten-4";
    }
  },

  stringToMoney(moneyString) {
    moneyString = moneyString.toString();
    moneyString = moneyString.replaceAll("€", "");
    moneyString = moneyString.replaceAll(".", "");
    moneyString = moneyString.replaceAll(",", "");
    return parseInt(moneyString);
  },

  moneyToString(money, suffix = "") {
    const moneyInt = Number(money) / 100;

    return (
      moneyInt.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + suffix
    );
  },

  DEstringToNumber(string) {
    string = string.toString();
    string = string.replaceAll("€", "");
    string = string.replaceAll(" ", "");

    string = string.replaceAll(".", "");
    string = string.replaceAll(",", ".");

    return Number(string);
  },

  DEnumberToString(number, decimals = 2, suffix = "") {
    return this.numberToString(this.DEstringToNumber(number), decimals, suffix);
  },

  numberToString(number, decimals = 2, suffix = "") {
    let _number = Number(number);

    if (!_number) {
      return "NaN";
    }
    return (
      _number.toLocaleString("de-DE", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      }) + suffix
    );
  }
};
