<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>person</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-weight-light">Name:</div>
        {{ user.forename + " " + user.surname }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>cake</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-weight-light">Geburtsdatum:</div>
        {{ parseDate(user.birthday) }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="user.email !== user.accountEmail">
      <v-list-item-icon>
        <v-icon>email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-weight-light">Email:</div>
        {{ user.email }}
      </v-list-item-content>
      <v-list-item-action v-if="showEmailInfo">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">info</v-icon>
          </template>
          <div>Email gespeichert in NaMi.</div>
          <div>Scouttools benutzt ausschließlich</div>
          <div>deine Account Email.</div>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="user.parentEmail">
      <v-list-item-icon>
        <v-icon>email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-weight-light">
          Email Vertretungsberechtigter:
        </div>
        {{ user.parentEmail }}
      </v-list-item-content>
      <v-list-item-action v-if="showEmailInfo">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">info</v-icon>
          </template>
          <div>Email gespeichert in NaMi.</div>
          <div>Scouttools benutzt ausschließlich</div>
          <div>deine Account Email.</div>
        </v-tooltip>
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>place</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-weight-light">Anschrift:</div>
        {{ user.street + " " + user.postalCode + " " + user.city }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>local_phone</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-weight-light">Telefon:</div>
        {{ parsePhones(user) }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>menu_book</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <div class="font-weight-light">Zeitschriftenversand:</div>
        <div v-if="user.namiMagazineDispatch">
          wird versandt
        </div>
        <div v-else>
          wird nicht versandt
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
import Parser from "@/utils/Parser";

export default {
  name: "UserDetailList",
  props: {
    userId: {
      type: Number,
      required: true
    },
    showEmailInfo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("users", { userGetter: "user" }),
    user() {
      return this.userGetter(this.userId);
    }
  },
  methods: {
    parsePhones(user) {
      return Parser.parseTelefonNumber(user.phone1, user.phone2, user.phone3);
    },
    parseDate(date) {
      return Parser.parseDate(new Date(date));
    },
    parseDuration(activity) {
      if (!activity.end || activity.start === activity.end) {
        return "Seit " + this.parseDate(activity.start);
      } else {
        return (
          this.parseDate(activity.start) + " - " + this.parseDate(activity.end)
        );
      }
    },
    nameToColor(name) {
      return Parser.nameToColor(name);
    }
  }
};
</script>

<style scoped></style>
