<template>
  <v-app :dark="setTheme">
    <router-view name="navbar" />
    <v-main>
      <router-view class />
      <router-view name="footer" />
      <GlobalSnackbar />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import GlobalSnackbar from "@/components/utility/GlobalSnackbar";

export default {
  name: "App",
  components: { GlobalSnackbar },
  computed: {
    setTheme() {
      return this.$store.state.settings.darkMode;
    }
  },
  watch: {
    setTheme(newValue) {
      this.$vuetify.theme.dark = newValue;
    }
  },
  mounted() {
    this.$vuetify.theme.dark = this.setTheme;
  },
  created() {
    if (navigator.serviceWorker) {
      // Listen for swUpdated event and display refresh snackbar as required.
      document.addEventListener(
        "service-worker-updated",
        e => {
          console.log("service-worker-updated event detected");
          console.log(e);
          this.setUpdateAvailable(e.detail);
        },
        { once: true }
      );
      // Refresh all open app tabs when a new service worker is installed.
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });

      //listen on install prompt event
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.setInstallable(e);
      });

      //listen on app installed event
      window.addEventListener("appinstalled", () => {
        this.setNotInstallable();
        this.showSnackbar({ text: "App installiert" });
      });
    }
  },
  methods: {
    ...mapActions("snackbar", ["showSnackbar"]),
    ...mapActions("pwa", [
      "setUpdateAvailable",
      "setInstallable",
      "setNotInstallable"
    ])
  }
};
</script>
