import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Navbar from "../components/layout/Navbar";
import Landing from "../views/Landing";
import Activation from "../views/Activation";
import UserManagement from "../views/UserManagement";
import Dashboard from "../views/Dashboard";
import PasswordReset from "../views/password-reset/PasswordReset";
import ChangePassword from "../views/password-reset/ChangePassword";
import EmailActivation from "../views/EmailActivation";
import Footer from "@/components/layout/Footer";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    components: {
      default: Dashboard,
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/management",
    name: "management",
    components: {
      default: UserManagement,
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/landing",
    name: "landing",
    components: {
      default: Landing
    }
  },
  {
    path: "/activation",
    name: "activation",
    components: {
      default: Activation
    }
  },
  {
    path: "/password-reset",
    name: "password-reset",
    components: {
      default: PasswordReset,
      footer: Footer
    },
    props: {
      footer: {
        feedback: false,
        feedbackFixed: false
      }
    }
  },
  {
    path: "/password-reset/:resetToken",
    name: "change-password",
    components: {
      default: ChangePassword,
      footer: Footer
    },
    props: {
      footer: {
        feedback: false,
        feedbackFixed: false
      }
    }
  },
  {
    path: "/email-activation/:token",
    name: "email-activation",
    components: {
      default: EmailActivation,
      footer: Footer
    },
    props: {
      footer: {
        feedback: false,
        feedbackFixed: false
      }
    }
  },
  {
    path: "/events",
    name: "events",
    components: {
      default: () => import(/* webpackChunkName: "events" */ "../views/Events"),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/events/new",
    name: "new-event",
    components: {
      default: () =>
        import(/* webpackChunkName: "event-editor" */ "../views/EventEditor"),
      navbar: Navbar,
      footer: Footer
    }
  },
  {
    path: "/events/:eventId/edit",
    name: "edit-event",
    components: {
      default: () =>
        import(/* webpackChunkName: "event-editor" */ "../views/EventEditor"),
      navbar: Navbar,
      footer: Footer
    }
  },
  {
    path: "/events/:eventId/operation",
    name: "event-operation",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "event-operation" */ "../views/EventOperationDetails"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      default: true
    }
  },
  {
    path: "/events/:eventId/registration",
    name: "registration",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "registration" */ "../views/Registration.vue"
        ),
      navbar: Navbar,
      footer: Footer
    }
  },
  {
    path: "/events/:eventId/registration/detail",
    name: "registration-detail",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "registration" */ "../views/RegistrationDetail.vue"
        ),
      navbar: Navbar,
      footer: Footer
    }
  },
  {
    path: "/events/public/:token",
    name: "event-public",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "public-registration" */ "../views/PublicEventView.vue"
        ),
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/events/public/registration/:token",
    name: "event-public-registration",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "public-registration" */ "../views/PublicEventRegistration.vue"
        ),
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/grant",
    name: "grant",
    components: {
      default: () =>
        import(/* webpackChunkName: "grant" */ "../views/GrantGenerator"),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/contribution-collection",
    name: "contribution-collection",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "grant" */ "../views/ContributionCollection"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/financial",
    components: {
      default: () =>
        import(/* webpackChunkName: "financial" */ "../views/Financial"),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    },
    children: [
      {
        path: "",
        name: "financial",
        redirect: "accounts"
      },
      {
        path: "accounts",
        name: "financial/accounts",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "financial" */ "../views/financial/Accounts"
            )
        }
      },
      {
        path: "bookings",
        name: "financial/bookings",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "financial" */ "../views/financial/Bookings"
            )
        }
      },
      {
        path: "bankbookings",
        name: "financial/bankbookings",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "financial" */ "../views/financial/Bankbookings"
            )
        }
      },
      {
        path: "actions",
        name: "financial/actions",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "financial" */ "../views/financial/Actions"
            )
        }
      },
      {
        path: "errorchecks",
        name: "financial/errorchecks",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "financial" */ "../views/financial/Errorchecks"
            )
        }
      },
      {
        path: "auditlogs",
        name: "financial/auditlogs",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "financial" */ "../views/financial/Auditlogs"
            )
        }
      },
      {
        path: "settlements",
        name: "financial/settlements",
        components: {
          default: () =>
            import(
              /* webpackChunkName: "financial" */ "../views/financial/Settlements"
            )
        }
      }
    ]
  },
  {
    path: "/financial/settlements/show/:settlementId",
    name: "financial-settlement-show",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "financial" */ "../views/financial/SettlementShowView"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/financial/settlements/:settlementId",
    name: "financial-settlement-update",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "financial" */ "../views/financial/SettlementDetailView"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/financial/settlement/submission",
    name: "financial-settlements-submission",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "financial" */ "../views/financial/SettlementSubmission"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/financial/accounts/submission",
    name: "financial-account-submission",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "financial" */ "../views/financial/AccountSubmission"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/financial/accounts/:accountId",
    name: "financial-account-update",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "financial" */ "../views/financial/AccountDetailView"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/financial/bookings/submission",
    name: "financial-booking-submission",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "financial" */ "../views/financial/BookingSubmission"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/financial/bookings/:bookingId",
    name: "financial-booking-update",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "financial" */ "../views/financial/BookingDetailView"
        ),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/invoices",
    name: "invoices",
    components: {
      default: () =>
        import(/* webpackChunkName: "invoice" */ "../views/InvoiceOverview"),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/invoices/submission",
    name: "invoice-submission",
    components: {
      default: () =>
        import(/* webpackChunkName: "invoice" */ "../views/InvoiceSubmission"),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/invoices/:invoiceId",
    name: "invoice-details",
    components: {
      default: () =>
        import(/* webpackChunkName: "invoice" */ "../views/InvoiceDetailView"),
      navbar: Navbar,
      footer: Footer
    },
    props: {
      footer: {
        feedbackFixed: false
      }
    }
  },
  {
    path: "/email",
    name: "email",
    components: {
      default: () => import(/* webpackChunkName: "email" */ "../views/Email"),
      navbar: Navbar,
      footer: Footer
    }
  },
  {
    path: "/personal",
    name: "personal",
    components: {
      default: () =>
        import(/* webpackChunkName: "personal" */ "../views/Personal"),
      navbar: Navbar,
      footer: Footer
    }
  },
  {
    path: "/settings",
    name: "settings",
    components: {
      default: () =>
        import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
      navbar: Navbar,
      footer: Footer
    }
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue"),
      footer: Footer
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    // route level code-splitting
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/Privacy.vue"),
      footer: Footer
    }
  },
  {
    path: "/terms",
    name: "terms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () =>
        import(/* webpackChunkName: "about" */ "../views/Terms.vue"),
      footer: Footer
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  routes
});

export default router;

const routeGuard = async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  await store.restored;
  const publicPages = [
    "landing",
    "activation",
    "password-reset",
    "change-password",
    "email-activation",
    "event-public-registration",
    "event-public",
    "terms",
    "privacy"
  ];
  const authRequired = !publicPages.includes(to.name);
  let loggedIn = store.getters["user/loggedIn"];
  if (authRequired && !loggedIn) {
    return next("/landing");
  }

  next();
};

router.beforeEach(routeGuard);
