<template>
  <div>
    <h1>Datenschutzerklärung</h1>
    <div class="mt-8 mb-8">Stand: 6.11.2020</div>
    <p>
      Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend
      informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
    </p>
    <div class="mt-3">
      <span>Verantwortlicher für die Datenverarbeitung ist:</span><br />
      <div class="font-weight-medium ml-3">
        Alexander Heck<br />
        Lanzstr. 58<br />
        68775 Ketsch<br />
        datenschutz@dpsg-ketsch.de<br />
      </div>
    </div>
    <div class="mt-2">
      Bei Unklarheiten und Fragen dazu, wie Scouttools ihre Daten verarbeitet,
      kontaktieren Sie uns.
    </div>

    <div class="font-weight-medium mt-5">Zweck der Verarbeitung</div>
    <div>
      Die Webapp Scouttools ist eine Plattform zur Organisation der DPSG Ketsch.
      <br />
      Dazu bietet Scouttools folgende zentrale Funktionen:
    </div>
    <ul>
      <li>
        <i>Kontaktaufnahme</i> <br />
        Scouttools ermöglicht es Leitern die Kontaktdaten ihrer Gruppenkinder
        einzusehen, um sie oder ihre Eltern benachrichtigen zu können.
      </li>
      <li>
        <i>Kommunikation</i> <br />
        Durch eine zentrale Emailverwaltung können gezielt, der ganze Stamm oder
        einzelne Stufen über Neuigkeiten und Ereignisse informiert werden.
      </li>
      <li>
        <i>Anmeldung zu Aktionen</i> <br />
        Mit Scouttools können Mitglieder sich digital für Aktionen anmelden.
      </li>
    </ul>
    <p>
      Ohne die Verarbeitung Ihrer personenbezogenen Daten, ist eine sinnvolle
      Nutzung von Scouttools nicht möglich.
    </p>

    <div class="mt-5 font-weight-medium">Rechtsgrundlage der Verarbeitung</div>
    <p>
      Scouttools ist ein Instrument, damit die DPSG Ketsch Ihren Mitgliedern
      Gruppenstunden und Aktionen bestmöglich bieten kann. Dies bildet die
      Rechtsgrundlage (Art. 6 Abs. 1 Satz 1 Buchstabe b DSGVO). Im Falle von
      Funktionen, die dazu nicht essenziell sind, erfolgt die Verarbeitung
      aufgrund Ihrer Einwilligung. (Art. 6 Abs. 1 UAbs. 1 Buchstabe a DSGVO)
    </p>
    <p>
      Sie haben das Recht die Einwilligung gemäß Art. 7 Abs. 3 DSGVO jederzeit
      mit Wirkung für die Zukunft zu widerrufen.
    </p>

    <div class="text-h6 font-weight-bold">
      Verarbeitung personenbezogener Daten
    </div>
    <div>
      Dieser Abschnitt beschreibt welche Daten über Sie Scouttools verarbeitet
      und speichert.
    </div>
    <div class="font-weight-medium mt-5">Server-Log-Datei</div>
    <div>
      Bei einem Besuch von Scouttools übermittelt ihr Browser, wie auch beim
      Beusch jeder anderen Website automatisch Daten. Diese sind:
    </div>
    <ul>
      <li>
        Browsertyp und Browserversion
      </li>
      <li>
        verwendetes Betriebssystem
      </li>
      <li>
        Referrer URL
      </li>
      <li>
        Hostname des zugreifenden Rechners
      </li>
      <li>
        Datum und Uhrzeit der Serveranfrage
      </li>
      <li>
        IP-Adresse
      </li>
    </ul>
    <p>
      Diese Daten werden teilweise automatisch in einer so genannten Server-Log
      Datei gespeichert. Eine Zusammenführung dieser Daten mit anderen
      Datenquellen wird nicht vorgenommen.
    </p>
    <div class="font-weight-medium">Lokal im Browser gespeicherte Daten</div>
    <p>
      Souttools speichert bei der Verwendung lokal Daten ab. Diese Daten
      enthalten Anzeigeinformationen, um auch ohne Internetverbindung Scouttools
      verwenden zu können. Desweiteren werden ab dem Login abgerufene Daten
      teilweise gespeichert, um einen dauerhaften Login zu ermöglichen und auch
      offline Daten anzeigen zu können. Bei einem Logout werden alle
      personenbezogenen Daten, die lokal in ihrem Browser gespeichert wurden,
      gelöscht. Scouttools verwendet keine Cookies.
    </p>
    <div class="font-weight-medium">NaMi-Mitgliedsdaten</div>
    <p>
      Scouttools importiert Daten aus NaMi. NaMi ist die zentrale
      Mitgliederverwaltung der DPSG. Als Mitglied der DPSG Ketsch werden ihre
      Daten in NaMi gespeichert und durch Scouttools verwendet. Diese können Sie
      in Scouttools nicht selbstständig bearbeiten. Für eine Änderung dieser
      müssen Sie Kontakt zu uns aufnehmen.
    </p>
    <div class="font-weight-medium">Account-Aktivierung</div>
    <p>
      Für die Nutzung bei Scouttools muss ein Account erstellt werden. Dieser
      Vorgang unterscheidet sich bei Scouttools im Vergleich zu klassischen
      Wepapps. Zur Registrierung wird ein 20-stelliger Aktivierungscode
      verwendet. Dieser ist an ihre Mitgliedsdaten oder andere zuvor
      bereitgestellte Daten gekoppelt. Dieser Aktivierungscode ist mit einem
      sehr starken Passwort vergleichbar und hindert Dritte daran unbrechtigt
      einen Account in Ihrem Namen zu aktivieren. Bei der eigentlichen
      Erstellung werden die NaMi-Daten über Sie mit einer Email und einem
      Passwort verknüpft, welche zur zukünftigen Anmeldung bei Scouttools
      dienen. Ihr Passwort wird nicht in Klartext auf dem Server gespeichert.
    </p>
    <div class="font-weight-medium">Profildaten</div>
    <p>
      Scouttools bietet die Möglichkeit ein Avatar-Bild zu ihrem Profil
      hinzuzufügen. Dies ist freiwillig und es ist jederzeit möglich ein
      hochgeladenes Profilbild zu ändern oder zu löschen
    </p>
    <div class="font-weight-medium">Notfallkontakte</div>
    <p>
      Außerdem können Sie in Scouttools Notfallkontakte anlegen. Ein
      Notfallkontakt besteht aus dem Namen, dem Verwandschaftsverhältnis und
      einer bis drei Telefonnummern einer Person. Diese Daten sind für Leiter
      bestimmt, um im Notfall mehrere Möglichkeiten zu haben Angehörige zu
      informieren.
    </p>
    <div class="font-weight-medium">Aktions-Anmeldung</div>
    <p>
      Bei der Anmeldung zu einer Aktion, werden notwendige Informationen
      abgefragt. Je nach Art der Aktion werden Daten zur Ernährung, zu den
      Schwimmfähigkeiten und oder medizinische Daten abgefragt. Für die
      Teilnahme an einer Aktion ist es notwendig mindestens einen Notfallkontakt
      angelegt zu haben und den Teilnahmebedingungen zuzustimmen.
    </p>
    <div class="font-weight-medium">Feedback</div>
    <p>
      Wenn Sie die Feedback-Funktion von Scouttools nutzen, wird Ihre Nachricht
      an den Entwickler gesendet. Das Feedback ist nicht anonymisiert.
    </p>
    <div class="text-h6 font-weight-bold">
      Verfügbarkeit der personenbezogenen Daten
    </div>
    <p>
      Im Allgemeinen können Sie alle Ihre Daten in Scouttools selbst einsehen.
      Sofern umsetzbar, bietet Scouttools Ihnen die Änderung und Löschung der,
      von Ihnen erstellten, Daten. Für andere Benutzer von Scouttools sind Ihre
      persönlichen Daten nur dann einsehbar, wenn dies für die Ausübung deren
      Aufgabe notwendig ist. Beispielsweise können Leiter, nur die Kontaktdaten
      ihrer Gruppenmitglieder einsehen.
    </p>
    <div class="text-h6 font-weight-bold">Datenlöschung und Speicherdauer</div>
    <p>
      Alle personenbezogenen Daten werden gelöscht, sobald der Zweck der
      Speicherung entfällt. Beispielsweise werden die Anmeldedaten einer Aktion
      nach deren Abschluss und Nachbereitung gelöscht.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyStatement"
};
</script>

<style scoped>
p {
  text-align: justify;
}
</style>
