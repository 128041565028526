<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <nav>
    <v-app-bar dense text app clipped-left class="primary white--text">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text" />
      <v-img
        src="../../assets/scouttools_logo.svg"
        aspect-ratio="1"
        max-width="30"
        contain
        class="mx-md-3 mr-2"
      />
      <v-toolbar-title>
        <span class="title font-weight-bold">Scout</span>
        <span class="title font-weight-light">Tools</span>
      </v-toolbar-title>
      <v-spacer />
      <v-menu
        v-model="value"
        bottom
        :offset-y="true"
        max-width="320"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom :disabled="isMobile">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                v-on="{ ...onMenu, ...onTooltip }"
                icon
                class="mr-0 mr-md-1"
                height="40"
                width="40"
              >
                <user-avatar size="40" />
              </v-btn>
            </template>
            <div>
              <div>Account Information</div>
              <div class="font-weight-bold">
                {{ userInfo.forename + " " + userInfo.surname }}
              </div>
              <div class="font-weight-bold">{{ userInfo.accountEmail }}</div>
            </div>
          </v-tooltip>
        </template>

        <v-card>
          <div
            @click="$router.push('/personal')"
            style="cursor: pointer"
            v-ripple
          >
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <user-avatar size="40" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ userInfo.forename + " " + userInfo.surname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    userInfo.accountEmail
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <v-divider />

          <div @click="setDarkMode(!darkMode)" style="cursor: pointer" v-ripple>
            <v-list-item>
              <v-list-item-action>
                <v-icon>
                  {{ darkMode ? "brightness_5" : "brightness_4" }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ darkMode ? "Light Mode" : "Dark Mode" }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

          <v-card-actions>
            <v-spacer />
            <v-btn text color="primary" @click="doLogout()">
              <span>Logout</span>
              <v-icon right>exit_to_app</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      app
      clipped
      :expand-on-hover="drawer && !isMobile"
      :permanent="!isMobile"
      :temporary="isMobile"
      v-model="drawer"
    >
      <v-layout column fill-height>
        <v-list dense nav>
          <v-list-item class="hidden-sm-and-up">
            <v-list-item-action>
              <v-img
                src="../../assets/scouttools_logo.svg"
                aspect-ratio="1"
                contain
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="title">
                <span class="title font-weight-bold">Scout</span>
                <span class="title font-weight-light">Tools</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="hidden-sm-and-up" />
          <v-list-item to="/">
            <v-list-item-action>
              <v-icon>
                dashboard
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Dashboard
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/management" v-if="isAdmin">
            <v-list-item-action>
              <v-icon>
                people
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Benutzerverwaltung
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/email" v-if="isLeiter || isStavo || isAdmin">
            <v-list-item-action>
              <v-icon>
                mail
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Emails
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/grant" v-if="isStavo || isAdmin">
            <v-list-item-action>
              <v-icon>
                attach_money
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Zuschüsse
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/contribution-collection"
            v-if="isStavo || isAdmin || isTreasurer"
          >
            <v-list-item-action>
              <v-icon>
                attach_money
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Bankeinzug
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/invoices">
            <v-list-item-action>
              <v-badge dot :content="todos.length" :value="todos.length">
                <v-icon>
                  account_balance
                </v-icon>
              </v-badge>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Belege
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/financial" v-if="isStavo || isAdmin || isTreasurer">
            <v-list-item-action>
              <v-icon>
                mdi-cash-multiple
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Finanzen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/events">
            <v-list-item-action>
              <v-icon>
                card_travel
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Aktions-Anmeldung
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/personal">
            <v-list-item-action>
              <v-icon>
                person
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Persönlicher Bereich
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-spacer />
        <v-list-item
          v-if="updateAvailable"
          class="rounded"
          style="background-color: #69F0AE; max-height: 64px"
          @click="refreshApp()"
        >
          <v-list-item-action>
            <v-icon>
              refresh
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              &rarr; Update
            </v-list-item-title>
            <v-list-item-subtitle>
              Klicke, um zu aktualisieren
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list dense nav>
          <v-list-item>
            <v-list-item-action />
            <v-list-item-content>
              <v-list-item-title
                @click="$router.push('/privacy')"
                class="navbar-link-opacity navbar-link"
              >
                Datenschutzerklärung
              </v-list-item-title>
              <v-list-item-title
                @click="$router.push('/terms')"
                class="navbar-link-opacity navbar-link"
              >
                Nutzungsbedingungen
              </v-list-item-title>
              <v-list-item-title class="navbar-link-opacity">
                Version: {{ version }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="installable" @click="installApp()">
            <v-list-item-action>
              <v-icon>
                get_app
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                App Installieren
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/settings" v-show="false">
            <v-list-item-action>
              <v-icon>
                help
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Hilfe
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/settings">
            <v-list-item-action>
              <v-icon>
                settings
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Einstellungen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
  </nav>
</template>

<script>
/* eslint-disable no-console */

import { mapActions, mapGetters } from "vuex";
import Avatar from "../user/Avatar";

export default {
  components: {
    "user-avatar": Avatar
  },
  data() {
    return {
      drawer: false,
      value: false,
      version: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    darkMode() {
      return this.$store.state.settings.darkMode;
    },
    userAvatarText() {
      return this.$store.state.user.userInfo.name.charAt(0);
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    updateAvailable() {
      return this.$store.state.pwa.updateAvailable;
    },
    installable() {
      return this.$store.state.pwa.installable;
    },
    ...mapGetters("user", [
      "isAdmin",
      "isLeiter",
      "isStavo",
      "isTreasurer",
      "isNamiMember"
    ]),
    ...mapGetters("invoices", ["todos"])
  },
  methods: {
    ...mapActions("user", ["doLogout"]),
    ...mapActions("settings", ["setDarkMode"]),
    ...mapActions("uistates", ["setGridViewActive", "setSearchInput"]),
    ...mapActions("pwa", ["refreshApp", "installApp"])
  }
};
</script>

<style scoped>
.navbar-link-opacity {
  opacity: 0.6;
}
.navbar-link {
  cursor: pointer;
}
.navbar-link:hover {
  text-decoration: underline;
}
</style>
