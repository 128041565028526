<template>
  <div>
    <v-card-text class="pl-5">
      <v-checkbox
        v-for="header in headers"
        :key="header.title"
        :label="header.title"
        v-model="checked"
        :value="header.title"
        hide-details
      />
    </v-card-text>
    <div style="position: sticky; bottom: 0; background-color: white">
      <v-divider />
      <v-card-actions class="py-3">
        <v-text-field
          label="Dateiname"
          v-model="fileNameModel"
          outlined
          dense
          hide-details
          class="ml-3 pt-0"
        />
        <v-btn
          elevation="0"
          color="primary"
          height="40"
          class="mx-3"
          @click="exportList()"
        >
          Export
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import CSV from "@/utils/CSV";

export default {
  name: "CsvExport",
  props: {
    data: {
      type: Array,
      required: true
    },
    fileName: {
      type: String,
      default: "Export.csv"
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {
            title: "Header nicht angeben",
            propName: "",
            // eslint-disable-next-line no-unused-vars
            modifier: (value, object) => {
              return value;
            }
          }
        ];
      }
    }
  },
  data() {
    return {
      checked: [],
      fileNameModel: ""
    };
  },
  methods: {
    exportList() {
      let propNames = this.headers
        .filter(header => header.modifier)
        .map(header => header.propName);
      let processedData = this.data.map(entry => {
        let modifiedEntry = { ...entry };
        Object.keys(entry).forEach(key => {
          if (propNames.includes(key)) {
            modifiedEntry[key] = this.headers
              .find(header => header.propName === key)
              .modifier(entry[key], entry);
          }
        });
        return modifiedEntry;
      });
      CSV.exportCSVFile(
        this.headers.filter(header => this.checked.includes(header.title)),
        processedData,
        this.fileNameModel
      );
      this.$emit("list-exported");
    }
  },
  mounted() {
    this.fileNameModel = this.fileName;
    this.checked = this.headers.map(header => header.title);
  }
};
</script>

<style scoped></style>
