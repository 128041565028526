<template>
  <div class="mt-md-3 mx-md-5 mx-lg-13">
    <v-row class="ml-0" v-if="!isMobile">
      <v-card outlined class="ma-3" width="240" height="150">
        <v-card-title class="justify-center">
          Benutzer
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center align-center">
            <span class="display-2">{{ users.length }}</span>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card outlined class="ma-3" width="240" height="150">
        <v-card-title class="justify-center">
          Aktive Accounts
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center align-center">
            <span class="display-2">{{ activeAccountNumber }}</span>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card outlined class="ma-3" width="240" height="150">
        <v-card-title class="justify-center">
          Offene Aktivierungen
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center align-center">
            <span class="display-2">{{ openActivation }}</span>
          </v-row>
        </v-card-text>
      </v-card>

      <user-analysis-report class="ma-3" />
    </v-row>

    <v-card :outlined="!isMobile" :flat="isMobile" class="my-3 mx-md-3">
      <v-card-title>
        <span>Benutzerverwaltung</span>
        <v-spacer />
        <v-btn color="primary" class="mr-3" @click="exportDialog = true">
          <v-icon>
            publish
          </v-icon>
        </v-btn>
        <v-btn dark color="primary" class="mr-3" @click="refreshUsers()">
          <v-icon>
            sync
          </v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="mr-6"
                  width="180"
                  color="primary"
                  dark
                  v-on="{ ...tooltip, ...menu }"
                  >{{ selectedFilter }}</v-btn
                >
              </template>
              <span>Filtere nach Mitglieds-Rolle</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="selectedFilter = item"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          class="pt-0 mt-md-0 mt-5"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="searchedUsers"
        :item-class="highlightOwnEntry"
      >
        <template v-slot:item.name="{ item }">
          <span :class="{ 'font-weight-bold': item.id === userInfo.id }">{{
            item.forename + " " + item.surname
          }}</span>
        </template>
        <template v-slot:item.birthday="{ item }">
          <span :class="{ 'font-weight-bold': item.id === userInfo.id }">{{
            formatBirthday(item.birthday)
          }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            icon
            small
            @click="$router.push('/personal')"
            v-if="item.id === userInfo.id"
          >
            <v-icon small>
              person
            </v-icon>
          </v-btn>
          <template v-else>
            <v-btn icon small @click="openUserDetailDialog(item.id)">
              <v-icon small>
                info
              </v-icon>
            </v-btn>
            <v-btn
              v-if="item.accountEmail"
              icon
              small
              @click="
                setAccountActive({
                  userId: item.id,
                  active: !item.accountActive
                })
              "
            >
              <v-icon small>
                {{ item.accountActive ? "lock" : "lock_open" }}
              </v-icon>
            </v-btn>
            <v-menu
              v-if="item.accountEmail"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      small
                      v-on="{ ...tooltip, ...menu }"
                      :disabled="!item.accountActive"
                      ><v-icon small>
                        person_add
                      </v-icon></v-btn
                    >
                  </template>
                  <span>Zugriffsrechte Rolle hinzufügen</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item>
                  <v-checkbox
                    v-model="item.roles"
                    value="Admin"
                    class="mt-0 pt-0"
                    hide-details
                    label="Admin"
                    @change="pressRoleCheckbox(item.roles, item.id, 'Admin')"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </template>
        <template v-slot:item.account="{ item }">
          <div v-if="item.accountEmail">
            <span v-if="!item.accountActive" class="font-weight-light"
              >Inaktiver</span
            >
            Account:
            <div class="font-weight-bold">{{ item.accountEmail }}</div>
          </div>
          <div v-if="!item.accountEmail && !item.accountActivationCode">
            <v-btn small @click="createActivationCode(item.id)">
              <v-icon>add</v-icon>Aktivierungscode
            </v-btn>
          </div>
          <div v-if="item.accountActivationCode">
            Aktivierungscode:
            <div
              class="font-weight-bold"
              style="font-family: monospace, monospace"
            >
              {{ formattedActivationCode(item.accountActivationCode) }}
              <v-btn
                small
                icon
                @click="shareActivationCode(item.accountActivationCode)"
              >
                <v-icon small>
                  share
                </v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:item.activities="{ item }">
          <div v-if="!item.activities">
            Keine Aktivitäten
          </div>
          <div v-else>
            <v-chip
              class="mr-1 my-1"
              v-for="activity in filterActiveActivities(item.activities)"
              :key="activity.nami_id"
              :color="nameToColor(activity.name)"
              >{{ activity.name }}</v-chip
            >
          </div>
        </template>
        <template v-slot:item.roles="{ item }">
          <div
            v-if="
              item.accountActive &&
                item.roles.filter(role => role === 'Admin').length > 0
            "
          >
            <v-icon>
              add
            </v-icon>
            <v-chip
              class="mr-1 my-1"
              v-for="role in item.roles.filter(role => role === 'Admin')"
              :key="role"
              :color="nameToColor(role)"
              >{{ role }}</v-chip
            >
          </div>
        </template></v-data-table
      >
      <user-detail-dialog ref="userDetailDialog" />
    </v-card>
    <csv-export-dialog
      v-model="exportDialog"
      :data="users"
      :headers="exportHeaders"
    />
  </div>
</template>

<script>
import Parser from "../utils/Parser";
import Clipboard from "../utils/Clipboard";
import AccountAPI from "../api/AccountAPI";
import { mapActions, mapGetters } from "vuex";
import UserDetailDialog from "@/components/user/UserDetailDialog";
import UserAnalysisReport from "@/components/user/UserAnalysisReport";
import CsvExportDialog from "@/components/utility/CsvExportDialog";
export default {
  name: "MemberManagement",
  components: { CsvExportDialog, UserAnalysisReport, UserDetailDialog },
  data() {
    return {
      search: "",
      items: [
        "Alle",
        "Wölflinge",
        "Jungpfadfinder",
        "Pfadfinder",
        "Rover",
        "Leiter"
      ],
      selectedFilter: "Alle",
      headers: [
        { text: "Name", value: "name" },
        { text: "Geburtsdatum", value: "birthday", sortable: false },
        { text: "Aktivitäten", value: "activities", sortable: false },
        { text: "Zugriffsrechte Rollen", value: "roles", sortable: false },
        { text: "Account", value: "account", sortable: false },
        { text: "Aktionen", value: "action", sortable: false }
      ],
      exportDialog: false,
      exportHeaders: [
        {
          title: "Name",
          propName: "forename",
          modifier: (forename, user) => {
            return `${forename} ${user.surname}`;
          }
        },
        {
          title: "Adresse",
          propName: "street",
          modifier: (street, user) => {
            return `${street} ${user.postalCode} ${user.city}`;
          }
        },
        {
          title: "Geburtstag",
          propName: "birthday",
          modifier: birthday => {
            return Parser.parseDate(new Date(birthday));
          }
        },
        {
          title: "Email",
          propName: "email",
          modifier: (email, user) => {
            return email || user.parentEmail || "";
          }
        },
        {
          title: "Telefon",
          propName: "phone1",
          modifier: (phone1, user) => {
            return Parser.parseTelefonNumber(phone1, user.phone2, user.phone3);
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters("users", ["user"]),
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    users() {
      return this.$store.state.users.users;
    },
    filteredUsers() {
      switch (this.selectedFilter) {
        case "Alle":
          return this.users;
        case "Wölflinge":
          return this.filterUsers("Wölfling");
        case "Jungpfadfinder":
          return this.filterUsers("Jungpfadfinder");
        case "Pfadfinder":
          return this.filterUsers("Pfadfinder");
        case "Rover":
          return this.filterUsers("Rover");
        case "Leiter":
          return this.users.filter(entity => {
            return (
              entity.activities &&
              (entity.activities
                .filter(activity => activity.active)
                .map(activity => activity.name)
                .includes("Wölflingsleiter") ||
                entity.activities
                  .filter(activity => activity.active)
                  .map(activity => activity.name)
                  .includes("Jungpfadfinderleiter") ||
                entity.activities
                  .filter(activity => activity.active)
                  .map(activity => activity.name)
                  .includes("Pfadfinderleiter") ||
                entity.activities
                  .filter(activity => activity.active)
                  .map(activity => activity.name)
                  .includes("Roverleiter"))
            );
          });
        default:
          return this.users;
      }
    },
    searchedUsers() {
      if (this.search === "") {
        return this.filteredUsers;
      }
      let splitted = this.search
        .split(" ")
        .map(string => string.toLowerCase())
        .filter(string => string);
      return this.filteredUsers.filter(user => {
        for (let search of splitted) {
          if (
            !(
              user.forename.toLowerCase().includes(search) ||
              user.surname.toLowerCase().includes(search) ||
              Parser.parseDate(new Date(user.birthday)).includes(search) ||
              (user.roles &&
                user.roles.find(role => role.toLowerCase().includes(search))) ||
              (user.activities &&
                user.activities.find(
                  activity =>
                    activity.active &&
                    activity.name.toLowerCase().includes(search)
                ))
            )
          ) {
            return false;
          }
        }
        return true;
      });
    },
    activeAccountNumber() {
      return this.users.filter(
        entity => entity.accountEmail && entity.accountActive
      ).length;
    },
    openActivation() {
      return this.users.filter(entity => entity.accountActivationCode).length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions("users", [
      "refreshUsers",
      "createActivationCode",
      "setAccountActive"
    ]),
    ...mapActions("snackbar", ["showSnackbar"]),
    multiSearch(user, splitted) {
      splitted.forEach(searchString => {
        if (this.searchFunction(user, searchString.toLowerCase())) {
          return true;
        }
      });
      return false;
    },
    searchFunction(user, search) {
      return (
        user.forename.toLowerCase().includes(search) ||
        user.surname.toLowerCase().includes(search) ||
        Parser.parseDate(new Date(user.birthday)).includes(search) ||
        (user.roles &&
          user.roles.find(role => role.toLowerCase().includes(search))) ||
        (user.activities &&
          user.activities.find(
            activity =>
              activity.active && activity.name.toLowerCase().includes(search)
          ))
      );
    },
    filterUsers(activityName) {
      return this.users.filter(user => {
        return (
          user.activities &&
          user.activities
            .filter(activity => activity.active)
            .map(activity => activity.name)
            .includes(activityName)
        );
      });
    },
    formatBirthday(birthday) {
      return Parser.parseDate(new Date(birthday));
    },
    formattedActivationCode(code) {
      return Parser.formatActivationCode(code);
    },
    filterActiveActivities(activities) {
      return activities.filter(activity => {
        return activity.active;
      });
    },
    nameToColor(name) {
      return Parser.nameToColor(name);
    },
    pressRoleCheckbox(roles, accountId, role) {
      if (roles.includes(role)) {
        this.addRole(accountId, role);
      } else {
        this.removeRole(accountId, role);
      }
    },
    addRole(accountId, role) {
      AccountAPI.addRoles(accountId, [role]).catch(error => {
        console.log(error);
        this.showSnackbar({
          text: "error.response.data",
          color: "error"
        });
      });
    },
    removeRole(accountId, role) {
      AccountAPI.removeRoles(accountId, [role]).catch(error => {
        console.log(error);
        this.showSnackbar({
          text: "error.response.data",
          color: "error"
        });
      });
    },
    shareActivationCode(acitvationCode) {
      function copyToClipboard() {
        Clipboard.copyTextToClipboard(acitvationCode);
        this.showSnackbar({
          text: "Aktivierungscode kopiert"
        });
      }

      if (navigator.share) {
        navigator
          .share({
            title: "Aktivierungscode",
            text: acitvationCode
          })
          .catch(() => {
            copyToClipboard.call(this);
          });
      } else {
        copyToClipboard.call(this);
      }
    },
    openUserDetailDialog(userId) {
      this.$refs.userDetailDialog.open(userId);
    },
    highlightOwnEntry(item) {
      if (item.id === this.userInfo.id) {
        return "highlight-row";
      }
      return "";
    }
  },
  mounted() {
    this.refreshUsers();
  }
};
</script>

<style scoped>
.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>

<style>
.own-row {
  background-color: #dddddd;
}
.highlight-row tr:hover {
  background-color: #dddddd !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: #dddddd;
}
</style>
