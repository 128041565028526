export default {
  namespaced: true,

  state: {
    darkMode: false
  },

  mutations: {
    setDarkMode(state, mode) {
      state.darkMode = mode;
    }
  },

  actions: {
    setDarkMode({ commit }, mode) {
      commit("setDarkMode", mode);
    }
  }
};
