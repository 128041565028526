<template>
  <div v-if="!isMobile">
    <v-btn
      color="primary"
      width="170"
      class="fixed-button rotated"
      @click="expand()"
    >
      Feedback geben
    </v-btn>
    <transition name="slide">
      <div v-if="active" class="fixed-card">
        <v-row>
          <v-btn
            color="primary"
            width="170"
            class="rotated mr-4"
            @click="close()"
          >
            Schließen
          </v-btn>
          <v-card width="300">
            <v-card-text>
              <v-form ref="messageForm" :disabled="!active">
                <v-textarea
                  height="200"
                  no-resize
                  v-model="message"
                  ref="text"
                  outlined
                  class="mr-1 mb-n4"
                  placeholder="Dein Feedback..."
                  @keyup.ctrl.enter="createFeedback()"
                  @keyup.esc="close()"
                  :rules="messageRule"
                >
                </v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                width="265"
                color="success"
                class="ml-2 mb-2"
                @click="createFeedback()"
              >
                Abschicken
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </div>
    </transition>
  </div>
</template>

<script>
import FeedbackAPI from "@/api/FeedbackAPI";
import { mapActions } from "vuex";

export default {
  name: "Feedback",
  data() {
    return {
      active: false,
      message: "",
      messageRule: []
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    }
  },
  methods: {
    ...mapActions("snackbar", ["showSnackbar"]),
    expand() {
      this.active = true;
      this.$nextTick(() => {
        this.$refs.text.focus();
      });
    },
    close() {
      this.messageRule = [];
      this.active = false;
    },
    createFeedback() {
      this.activateRule();
      this.$nextTick(() => {
        if (!this.$refs.messageForm.validate()) {
          return;
        }
        let feedback = {
          message: this.message,
          path: this.$route.fullPath
        };
        FeedbackAPI.newFeedback(feedback)
          .then(() => {
            this.close();
            this.message = "";
            this.showSnackbar({
              text: "Feedback gesendet"
            });
          })
          .catch(() => {
            this.showSnackbar({
              text: "Fehler beim Feedback Senden aufgetreten",
              color: "error"
            });
          });
      });
    },
    activateRule() {
      // this is a Workaround for a visiual effect, when closing the validation is not triggered
      this.messageRule = [v => !!v || "Bitte schreibe einen Feedback Text."];
    }
  }
};
</script>

<style scoped>
.rotated {
  transform-origin: right;
  transform: rotate(-90deg);
}
.fixed-button {
  position: fixed;
  right: 15px;
  top: 200px;
}
.fixed-card {
  position: fixed;
  top: 200px;
  right: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.55s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
</style>
