<template>
  <v-timeline dense>
    <v-timeline-item small fill-dot color="white" right>
      <span>Eintrittsdatum: </span>
      <span v-text="parseDate(user.namiEntryDate)"></span>
    </v-timeline-item>
    <v-timeline-item
      right
      fill-dot
      v-for="activity in user.activities"
      :key="activity.nami_id"
      :color="nameToColor(activity.name)"
    >
      <v-card width="350">
        <v-card-title>
          <span>{{ activity.name }}</span>
        </v-card-title>
        <v-card-text>
          <span v-text="parseDuration(activity)"></span>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import Parser from "@/utils/Parser";

export default {
  name: "ScoutingHistory",
  props: {
    userId: Number,
    own: Boolean
  },
  computed: {
    user() {
      if (this.own) {
        return this.$store.state.user.userInfo;
      } else {
        return this.$store.getters["users/user"](this.userId);
      }
    }
  },
  methods: {
    parseDate(date) {
      return Parser.parseDate(new Date(date));
    },
    parseDuration(activity) {
      if (!activity.end || activity.start === activity.end) {
        return "Seit " + this.parseDate(activity.start);
      } else {
        return (
          this.parseDate(activity.start) + " - " + this.parseDate(activity.end)
        );
      }
    },
    nameToColor(name) {
      return Parser.nameToColor(name);
    }
  }
};
</script>

<style scoped></style>
