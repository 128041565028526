export default {
  namespaced: true,

  state: {
    text: "",
    color: "",
    timout: "",
    position: undefined,
    actions: []
  },

  mutations: {
    SHOW_SNACK(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
      state.position = payload.position;
      state.actions = payload.actions;
    }
  },

  actions: {
    showSnackbar({ commit }, payload) {
      commit("SHOW_SNACK", payload);
    }
  }
};
