import InvoiceAPI from "@/api/InvoiceAPI";

const updateInterval = 30 * 1000; //30 seconds

export default {
  namespaced: true,

  state: {
    invoices: [],
    invoices_last_updated: 0,
    tags: [],
    tags_last_updated: 0,
    draft_invoice: {}
  },

  mutations: {
    updateDraftInvoice(state, draft_invoice) {
      state.draft_invoice = draft_invoice;
    },

    updateInvoices(state, invoices) {
      state.invoices = invoices;
      state.invoices_last_updated = Date.now();
    },
    updateInvoice(state, invoice) {
      let index = state.invoices.findIndex(inv => inv.id === invoice.id);
      Object.assign(state.invoices[index], invoice);
    },
    updateInvoiceReceiptCopy(state, payload) {
      let index = state.invoices.findIndex(inv => inv.id === payload.invoiceId);
      let invoice = state.invoices[index];
      invoice.receiptCopy = payload.receiptCopy;
      Object.assign(state.invoices[index], invoice);
    },
    addInvoice(state, invoice) {
      state.invoices.push(invoice);
    },
    updateTags(state, tags) {
      state.tags = tags;
      state.tags_last_updated = Date.now();
    },
    addTag(state, tag) {
      state.tags.push(tag);
    },
    updateTag(state, updatedTag) {
      let id = state.tags.findIndex(tag => tag.id === updatedTag.id);
      Object.assign(state.tags[id], updatedTag);
    },
    removeTag(state, tagId) {
      let id = state.tags.findIndex(tag => tag.id === tagId);
      state.tags.splice(id, 1);
    }
  },

  actions: {
    fetchInvoices({ state, commit, dispatch }) {
      if (
        !state.invoices ||
        !state.invoices_last_updated ||
        Date.now() - state.invoices_last_updated > updateInterval
      ) {
        InvoiceAPI.getInvoices()
          .then(invoices => {
            commit("updateInvoices", invoices);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: "Rechnungen konnten nicht aktualisiert werden",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    submitInvoice({ commit, dispatch }, invoiceSubmission) {
      InvoiceAPI.submitInvoice(invoiceSubmission)
        .then(invoice => {
          commit("addInvoice", invoice);
          //We update invoice, so we don't need draft anymore
          commit("updateDraftInvoice", {});
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Rechnung konnte nicht eingereicht werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    async updateInvoice({ commit, dispatch }, updatedInvoice) {
      InvoiceAPI.updateInvoice(updatedInvoice)
        .then(invoice => {
          commit("updateInvoice", invoice);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Rechnung konnte nicht aktualisiert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    async addInvoiceReceiptCopyParts({ commit, dispatch }, payload) {
      await InvoiceAPI.addInvoiceReceiptCopyParts(
        payload.receiptCopyParts,
        payload.invoiceId
      )
        .then(receiptCopy => {
          commit("updateInvoiceReceiptCopy", {
            invoiceId: payload.invoiceId,
            receiptCopy: receiptCopy
          });
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Rechnung konnte nicht aktualisiert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    async deleteInvoiceReceiptCopyParts({ commit, dispatch }, payload) {
      await InvoiceAPI.deleteInvoiceReceiptCopyParts(
        payload.receiptCopyParts,
        payload.invoiceId
      )
        .then(receiptCopy => {
          commit("updateInvoiceReceiptCopy", {
            invoiceId: payload.invoiceId,
            receiptCopy: receiptCopy
          });
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Rechnung konnte nicht aktualisiert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    updateInvoiceStatus({ getters, commit, dispatch }, payload) {
      let invoice = getters.invoice(payload.invoiceId);
      let updatedInvoice = { ...invoice, ...payload };
      InvoiceAPI.updateInvoice(updatedInvoice)
        .then(invoice => {
          commit("updateInvoice", invoice);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Status der Rechnung konnte nicht aktualisiert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    fetchTags({ state, commit, dispatch }) {
      if (
        !state.tags ||
        !state.tags_last_updated ||
        Date.now() - state.tags_last_updated > updateInterval
      ) {
        InvoiceAPI.getTags()
          .then(tags => {
            commit("updateTags", tags);
          })
          .catch(error => {
            console.log(error);
            let snackbar = {
              text: "Tags konnten nicht aktualisiert werden",
              color: "error"
            };
            dispatch("snackbar/showSnackbar", snackbar, { root: true });
          });
      }
    },
    addTag({ commit, dispatch }, tagName) {
      let tag;
      InvoiceAPI.createTag(tagName)
        .then(tagId => {
          tag = {
            id: tagId,
            name: tagName,
            active: true
          };
          commit("addTag", tag);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Neuer Tag konnte nicht erstellt werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
      return tag;
    },
    updateTag({ commit, dispatch }, updatedTag) {
      InvoiceAPI.updateTag(updatedTag)
        .then(() => {
          commit("updateTag", updatedTag);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Tag konnte nicht aktualisiert werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    removeTag({ commit, dispatch }, tagId) {
      InvoiceAPI.removeTag(tagId)
        .then(() => {
          commit("removeTag", tagId);
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Tag konnte nicht gelöscht werden",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    }
  },

  getters: {
    invoice: state => id => {
      return state.invoices.find(invoice => invoice.id === id);
    },
    tag: state => id => {
      return state.tags.find(tag => tag.id === id);
    },
    activeTags: state => {
      return state.tags.filter(tag => tag.active);
    },
    todos: (state, getters, rootState, rootGetters) => {
      let todos = [];
      state.invoices
        .filter(invoice => invoice.submitter === rootState.user.userInfo.id)
        .forEach(invoice => {
          switch (invoice.status) {
            case "Approved":
            case "Paid":
              todos.push({
                type: "OriginalReceiptHandover",
                invoiceId: invoice.id
              });
              break;
          }
        });
      if (
        !(
          rootGetters["user/isAdmin"] ||
          rootGetters["user/isStavo"] ||
          rootGetters["user/isTreasurer"]
        )
      )
        return todos;
      state.invoices.forEach(invoice => {
        switch (invoice.status) {
          case "Open":
            todos.push({ type: "DecisionNeeded", invoiceId: invoice.id });
            break;
          case "Approved":
            todos.push({ type: "Payment", invoiceId: invoice.id });
            break;
          case "Paid":
            todos.push({ type: "StoreOriginalReceipt", invoiceId: invoice.id });
            break;
        }
      });
      return todos;
    }
  }
};
