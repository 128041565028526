import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/es5/locale/de";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: "de"
  },
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        tertiary: "#f5f5f5"
      },
      dark: {
        tertiary: "#16161a"
      }
    }
  }
});
