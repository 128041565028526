import axiosClient from "./AxiosConfigFinancial";

export default {
  async getSettlements() {
    return axiosClient.get("/api/v1/settlements").then(response => {
      return response.data;
    });
  },
  async updateSettlement(settlementSubmission) {
    return axiosClient
      .put(
        "/api/v1/settlements/" + settlementSubmission.id,
        settlementSubmission,
        {}
      )
      .then(response => {
        return response.data;
      });
  },
  async submitSettlement(settlementSubmission) {
    return axiosClient
      .post("/api/v1/settlements", settlementSubmission, {})
      .then(response => {
        return response.data;
      });
  },

  async getAuditlogs() {
    return axiosClient.get("/api/v1/auditlogs").then(response => {
      return response.data;
    });
  },

  async deleteAccount(id) {
    return axiosClient.delete("/api/v1/accounts/" + id).then(response => {
      return response.data;
    });
  },
  async getAccounts() {
    return axiosClient.get("/api/v1/accounts").then(response => {
      return response.data;
    });
  },
  async submitAccount(accountSubmission) {
    return axiosClient
      .post("/api/v1/accounts", accountSubmission, {})
      .then(response => {
        return response.data;
      });
  },
  async updateAccount(accountSubmission) {
    return axiosClient
      .put("/api/v1/accounts/" + accountSubmission.id, accountSubmission, {})
      .then(response => {
        return response.data;
      });
  },
  async patchAccount(accountSubmission) {
    return axiosClient
      .patch("/api/v1/accounts/" + accountSubmission.id, accountSubmission)
      .then(response => {
        return response.data;
      });
  },

  async deleteBooking(id) {
    return axiosClient.delete("/api/v1/bookings/" + id).then(response => {
      return response.data;
    });
  },
  async getBookings() {
    return axiosClient.get("/api/v1/bookings").then(response => {
      return response.data;
    });
  },
  async submitBooking(bookingSubmission) {
    return axiosClient
      .post("/api/v1/bookings", bookingSubmission, {})
      .then(response => {
        return response.data;
      });
  },
  async updateBooking(bookingSubmission) {
    console.log("API", bookingSubmission, bookingSubmission.bank_booking);
    if (bookingSubmission.bank_booking == undefined) {
      bookingSubmission.bank_booking = null;
    }
    return axiosClient
      .put("/api/v1/bookings/" + bookingSubmission.id, bookingSubmission, {})
      .then(response => {
        return response.data;
      });
  },
  async postBookingBatchDelete(ids) {
    return axiosClient
      .post("/api/v1/actions/BookingBatchDelete", ids, {})
      .then(response => {
        return response.data;
      });
  },
  async postBookingDuplicate(ids) {
    return axiosClient
      .post("/api/v1/actions/BookingDuplicate", ids, {})
      .then(response => {
        return response.data;
      });
  },
  async postBookingBatchBookAccount(data) {
    console.log("3", data);
    return axiosClient
      .post("/api/v1/actions/BookingBatchBookAccount", data, {})
      .then(response => {
        return response.data;
      });
  },

  async getBankBookings() {
    return axiosClient.get("/api/v1/bankbookings").then(response => {
      return response.data;
    });
  },
  async getBankSepaUpdate() {
    return axiosClient
      .get("/api/v1/actions/SepaUpdateBankBookings", { timeout: 120000 })
      .then(response => {
        return response.data;
      });
  },
  async postBankBookingCreateBooking(action_data) {
    return axiosClient
      .post("/api/v1/actions/BankBookingCreateBooking", action_data, {})
      .then(response => {
        return response.data;
      });
  }
};
