import axios from "axios";
import store from "../store";
import { Mutex } from "async-mutex";

const axiosClient = axios.create({
  timeout: 20000 // request timeout
});

export const mutex = new Mutex();

axiosClient.defaults.baseURL = process.env.VUE_APP_USER_API_BASE_URL;

axiosClient.interceptors.request.use(
  config => {
    if (["/login", "/refresh"].includes(config.url)) {
      return config;
    }
    let accessToken = store.state.user?.tokenPair?.accessToken;
    let eventRegistrationToken = store.state.user?.eventRegistrationToken;
    if (accessToken) {
      config.headers["Authorization"] = "bearer " + accessToken;
      return config;
    } else if (eventRegistrationToken) {
      config.headers["Event-Registration-Authorization"] =
        "EventRegistrationToken " + eventRegistrationToken;
      return config;
    } else {
      return config;
    }
  },
  error => {
    Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    if (error.message === "Network Error") {
      return Promise.reject(error);
    }
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      (error.response.data.includes("token is missing") ||
        error.response.data.includes("token is malformed") ||
        error.response.data.includes("token is invalid"))
    ) {
      originalRequest._retry = true;
      let refreshToken = "";
      if (
        store.state.user.tokenPair &&
        store.state.user.tokenPair.refreshToken
      ) {
        refreshToken = store.state.user.tokenPair.refreshToken;
      }
      return mutex.acquire().then(release => {
        let refreshTokenReloaded = store.state.user.tokenPair.refreshToken;
        if (refreshToken !== refreshTokenReloaded) {
          release();
          return axiosClient(originalRequest);
        }
        return axiosClient
          .post("/refresh", { refreshToken: refreshToken })
          .then(res => {
            if (res.status === 200) {
              store.dispatch("user/insertNewTokenPair", res.data);
              // return originalRequest object with Axios.
              return axiosClient(originalRequest);
            }
          })
          .catch(error => {
            console.log(error.response);
            if (error.response && error.response.status === 401) {
              store.dispatch("user/doLogout");
            } else {
              console.log("error occured while calling refresh");
            }
            return Promise.reject(error);
          })
          .finally(() => {
            release();
          });
      });
    }
    // return Error object with Promise
    return Promise.reject(error);
  }
);

export default axiosClient;
