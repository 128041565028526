import EventsAPI from "../../api/EventsAPI";

const eventUpdateInterval = 1000; // 1 second
const registrationsUpdateInterval = 1000; // 1 seconds
const eventsOperationInformationUpdateInterval = 1000; // 1 seconds

const emptyRegistration = {
  eventId: 0,
  currentStep: 0,
  errorState: false,
  dietData: {
    diet: "",
    intolerance: false,
    intoleranceDescription: ""
  },
  swimmingData: {
    swimmer: false,
    onlySupervisedSwimming: true
  },
  pocketMoneyData: {
    managedPocketMoney: false
  },
  medicalData: {
    bepanthen: false,
    fenistil: false,
    octenisept: false,
    tick: true,
    precondition: false,
    preconditionDescription: "",
    medication: false,
    medicationDescription: "",
    noSelfMedication: false,
    medicationPlan: ""
  },
  consentData: {
    agreement: false,
    dataReuse: false
  }
};

export default {
  namespaced: true,

  state: {
    events: [],
    events_last_updated: 0,
    registrations: [],
    registrations_last_updated: 0,
    registrationsInProgress: [],
    eventsOperationInformation: [],
    eventsOperationInformation_last_updated: 0
  },

  mutations: {
    updateEvents(state, events) {
      state.events = events;
      state.events_last_updated = Date.now();
    },
    addEvent(state, event) {
      state.events.push(event);
    },
    deleteEvent(state, eventId) {
      let index = state.events.map(event => event.id).indexOf(eventId);
      state.events.splice(index, 1);
    },
    updateEvent(state, payload) {
      let index = state.events.map(event => event.id).indexOf(payload.id);
      state.events[index] = payload;
    },
    updateEventsOperationInformation(state, eventsOperationInformation) {
      state.eventsOperationInformation = eventsOperationInformation;
      state.eventsOperationInformation_last_updated = Date.now();
    },
    updateRegistrations(state, registrations) {
      state.registrations = registrations;
      state.registrations_last_updated = Date.now();
    },
    beginRegistration(state, id) {
      let newRegistration = {
        ...emptyRegistration,
        eventId: id
      };
      state.registrationsInProgress.push(newRegistration);
    },
    unregister(state, registrationId) {
      let index = state.registrations
        .map(reg => reg.id)
        .indexOf(registrationId);
      state.registrations.splice(index, 1);
    },
    setStepDone(state, payload) {
      let index = state.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(payload.eventId);
      state.registrationsInProgress[index].currentStep = payload.step;
    },
    setStepError(state, payload) {
      let index = state.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(payload.eventId);
      if (index < 0) {
        console.log(
          "given event id associates no event in the registration progress"
        );
        return;
      }
      state.registrationsInProgress[index].errorState = payload.value;
    },
    saveRegistrationState(state, payload) {
      let index = state.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(payload.eventId);
      state.registrationsInProgress[index][payload.field] = payload.data;
    },
    cancelRegistrationInProgress(state, id) {
      let index = state.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(id);
      state.registrationsInProgress.splice(index, 1);
    },
    finishRegistration(state, registrationState) {
      let index = state.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(registrationState.eventId);
      state.registrationsInProgress.splice(index, 1);
      state.registrations.push(registrationState);
    },
    updateRegistration(state, regData) {
      let index = state.registrations.map(reg => reg.id).indexOf(regData.id);
      state.registrations[index] = regData;
    }
  },

  actions: {
    beginRegistration({ commit }, eventId) {
      commit("beginRegistration", eventId);
    },
    unregister({ commit, state }, eventId) {
      let registrationId = state.registrations
        .filter(reg => reg.eventId === eventId)
        .map(req => req.id);
      EventsAPI.cancelRegistration(registrationId)
        .then(() => {
          commit("unregister", registrationId);
        })
        .catch(error => {
          console.log(error);
        });
    },
    setStepDone({ commit }, payload) {
      commit("setStepDone", payload);
    },
    setStepError({ commit }, payload) {
      commit("setStepError", payload);
    },
    saveRegistrationState({ commit }, payload) {
      commit("saveRegistrationState", payload);
    },
    cancelRegistrationInProgress({ commit }, id) {
      commit("cancelRegistrationInProgress", id);
    },
    finishRegistration({ commit, state }, id) {
      let index = state.registrationsInProgress
        .map(reg => reg.eventId)
        .indexOf(id);
      let registrationState = state.registrationsInProgress[index];
      return EventsAPI.newRegistration(registrationState)
        .then(id => {
          registrationState.id = id;
          delete registrationState.currentStep;
          delete registrationState.errorState;
          commit("finishRegistration", registrationState);
          return id;
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateRegistration({ commit, dispatch }, regData) {
      EventsAPI.updateRegistration(regData)
        .then(() => {
          commit("updateRegistration", regData);
          let snackbar = {
            text: "Registrierungs Daten aktualisiert"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        })
        .catch(error => {
          console.log(error);
          let snackbar = {
            text: "Speichern der Änderungen fehlgeschlagen",
            color: "error"
          };
          dispatch("snackbar/showSnackbar", snackbar, { root: true });
        });
    },
    addEvent({ commit }, event) {
      EventsAPI.createEvent(event)
        .then(data => {
          event.id = data;
          commit("addEvent", event);
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteEvent({ commit }, eventId) {
      EventsAPI.deleteEvent(eventId)
        .then(() => {
          commit("deleteEvent", eventId);
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateEvent({ commit }, event) {
      EventsAPI.updateEvent(event)
        .then(() => {
          commit("updateEvent", event);
        })
        .catch(error => {
          console.log(error);
        });
    },
    refreshEvents({ commit, state }) {
      if (
        !state.events ||
        !state.events_last_updated ||
        Date.now() - state.events_last_updated > eventUpdateInterval
      ) {
        EventsAPI.getEvents()
          .then(events => {
            commit("updateEvents", events);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    refreshRegistrations({ commit, state }) {
      if (
        !state.registrations ||
        !state.registrations_last_updated ||
        Date.now() - state.registrations_last_updated >
          registrationsUpdateInterval
      ) {
        EventsAPI.getRegistrations()
          .then(registrations => {
            commit("updateRegistrations", registrations);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    refreshEventsOperationInformation({ commit, state }) {
      if (
        !state.eventsOperationInformation ||
        !state.eventsOperationInformation_last_updated ||
        Date.now() - state.eventsOperationInformation_last_updated >
          eventsOperationInformationUpdateInterval
      ) {
        return EventsAPI.getEventsOperationInformation()
          .then(eventsOperationInformation => {
            commit(
              "updateEventsOperationInformation",
              eventsOperationInformation
            );
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  getters: {
    event: state => id => {
      return state.events.find(event => event.id === id) || {};
    },
    registration: state => id => {
      return (
        state.registrations.find(reg => reg.id === id) || emptyRegistration
      );
    }
  }
};
