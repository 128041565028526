<template>
  <v-dialog v-model="open" width="500">
    <v-card width="500" class="pb-2">
      <div
        style="position: sticky; top: 0; z-index: 10; background-color: white"
      >
        <v-card-title>
          Daten-Export
          <v-spacer />
          <v-btn icon @click="open = false" class="mr-n2">
            <v-icon> mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="mt-n2">
          Wähle welche Informationen du exportieren möchtest:
        </v-card-subtitle>
        <v-divider />
      </div>
      <csv-export
        class="mt-n6 mb-n2"
        :data="data"
        :headers="headers"
        @list-exported="open = false"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import CsvExport from "@/components/utility/CsvExport";
export default {
  name: "CsvExportDialog",
  components: { CsvExport },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    fileName: {
      type: String,
      default: "Export.csv"
    }
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped></style>
