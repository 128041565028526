<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    scrollable
    width="900"
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
  >
    <v-card>
      <v-toolbar color="primary" class="sticky">
        <v-btn icon @click="dialog = false" class="white--text">
          <v-icon class="mr-1">
            keyboard_backspace
          </v-icon>
        </v-btn>
        <span class="text-h5 white--text">{{
          user.forename + " " + user.surname
        }}</span>
      </v-toolbar>
      <v-card-text class="mt-n2">
        <v-row no-gutters>
          <v-col class="col-12 col-md-6">
            <v-card-title>
              Perönliche Informationen
            </v-card-title>
            <user-detail-list :user-id="userId" />
          </v-col>
          <v-col class="col-12 col-md-6">
            <v-card-title>
              Pfadfinder Werdegang
            </v-card-title>
            <scouting-history :user-id="userId" :own="false" />
          </v-col>
        </v-row>
        <v-card-title>
          Notfallkontakte
        </v-card-title>
        <v-row no-gutters>
          <emergency-contact
            class="ma-2"
            no-edit
            :contact="contact"
            v-for="contact in user.emergencyContacts"
            :key="contact.id"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ScoutingHistory from "@/components/user/ScoutingHistory";
import UserDetailList from "@/components/user/UserDetailList";
import { mapGetters } from "vuex";
import EmergencyContact from "@/components/user/EmergencyContact";

export default {
  name: "UserDetailDialog",
  components: { EmergencyContact, ScoutingHistory, UserDetailList },
  data() {
    return {
      userId: 1,
      dialog: false
    };
  },
  computed: {
    ...mapGetters("users", { userGetter: "user" }),
    user() {
      return this.userGetter(this.userId);
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  },
  methods: {
    open(userId) {
      this.userId = userId;
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
