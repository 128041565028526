<template>
  <v-card
    outlined
    width="240"
    height="150"
    v-on="
      foundProblems.length > 0
        ? {
            click: () => {
              analysisDetailsDialog = true;
            }
          }
        : {}
    "
  >
    <v-card-title class="justify-center">
      Benutzer Analyse
    </v-card-title>
    <v-card-text>
      <v-row class="justify-center align-center">
        <v-icon
          :color="foundProblems.length === 0 ? 'green' : 'red'"
          class="mr-3 mt-3"
        >
          {{ foundProblems.length === 0 ? "check" : "report_problem" }}
        </v-icon>
        <span
          class="mt-3"
          style="font-size: 22px"
          v-if="foundProblems.length > 0"
          >{{ formatProblems() }}</span
        >
      </v-row>
    </v-card-text>
    <v-dialog tile scrollable v-model="analysisDetailsDialog" width="600">
      <v-card>
        <v-card-title>
          Benutzer Analyse Report
        </v-card-title>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>
                person
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                ><b>{{ formatProblems() }}</b
                ><span v-if="foundProblems.length > 0">
                  bei <b>{{ usersWithProblems() }}</b> Nutzern gefunden</span
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-model="emailDetails" no-action>
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-icon>
                  email
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b>{{ emailProblems().length }}</b> fehlende Email Adressen
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="problem in emailProblems()"
              :key="problem.userId"
              class="py-0 my-0"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    user(problem.userId).forename +
                      " " +
                      user(problem.userId).surname
                  }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="openUserDetailDialog(problem.userId)">
                  <v-icon>
                    person
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
          <v-list-group v-model="phoneDetails" no-action>
            <template v-slot:activator>
              <v-list-item-avatar>
                <v-icon>
                  phone
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b>{{ phoneProblems().length }}</b> fehlende Telefonnummern
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="problem in phoneProblems()"
              :key="problem.userId"
              class="py-0 my-0"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    user(problem.userId).forename +
                      " " +
                      user(problem.userId).surname
                  }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="openUserDetailDialog(problem.userId)">
                  <v-icon>
                    person
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
    </v-dialog>
    <user-detail-dialog ref="userDetailDialog" />
  </v-card>
</template>

<script>
/* eslint-disable no-unused-vars */

import { mapGetters } from "vuex";
import UserDetailDialog from "@/components/user/UserDetailDialog";

export default {
  name: "UserAnalysisReport",
  components: { UserDetailDialog },
  data() {
    return {
      analysisDetailsDialog: false,
      emailDetails: false,
      phoneDetails: false
    };
  },
  computed: {
    ...mapGetters("users", ["user"]),
    users() {
      return this.$store.state.users.users;
    },
    foundProblems() {
      return this.analyseUsers(this.users);
    }
  },
  methods: {
    analyseUsers(users) {
      let problems = [];
      users
        .filter(user => user.forename !== "Admin")
        .forEach(user => {
          problems.push(...this.analyseUser(user));
        });
      return problems;
    },
    analyseUser(user) {
      let problems = [];
      if (!user.email && !user.parentEmail) {
        problems.push({
          type: "email",
          userId: user.id
        });
      }
      if (!user.phone1 && !user.phone2 && !user.phone3) {
        problems.push({
          type: "phone",
          userId: user.id
        });
      }
      return problems;
    },
    formatProblems() {
      if (this.foundProblems.length === 0) {
        return "keine Probleme";
      } else if (this.foundProblems.length === 1) {
        return "1 Problem";
      } else {
        return this.foundProblems.length + " Probleme";
      }
    },
    usersWithProblems() {
      let uniqueUsers = new Set();
      this.foundProblems.forEach(problem => {
        uniqueUsers.add(problem.userId);
      });
      return uniqueUsers.size;
    },
    emailProblems() {
      return this.foundProblems.filter(problem => problem.type === "email");
    },
    phoneProblems() {
      return this.foundProblems.filter(problem => problem.type === "phone");
    },
    openUserDetailDialog(userId) {
      this.$refs.userDetailDialog.open(userId);
    }
  }
};
</script>

<style scoped></style>
