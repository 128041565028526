<template>
  <div>
    <v-card outlined>
      <v-toolbar flat :class="Parser.nameToColor(activityNames[0])">
        <v-toolbar-title>
          <span class="white--text">{{ listName }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          style="max-width: 150px"
        ></v-text-field>
        <v-btn icon @click="exportDialog = true">
          <v-icon>
            publish
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="searchedUsers"
        :expanded.sync="expanded"
        item-key="name"
        show-expand
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <p class="ma-1">Geburtsdatum: {{ item.birthday }}</p>
            <p class="ma-1">Anschrift: {{ item.address }}</p>
            <p class="ma-1">Telefon: {{ item.phone }}</p>
            <p class="ma-1">Email: {{ item.email }}</p>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <csv-export-dialog
      v-model="exportDialog"
      :data="allUsersRaw"
      :headers="exportHeaders"
    />
  </div>
</template>

<script>
import Parser from "../../utils/Parser";
import CsvExportDialog from "@/components/utility/CsvExportDialog";
export default {
  name: "StufenList",
  components: { CsvExportDialog },
  props: {
    listName: String,
    color: String,
    activityNames: Array
  },
  data() {
    return {
      Parser: Parser,
      headers: [
        { text: "Name", value: "name" },
        { text: "Telefon", value: "phone" }
      ],
      expanded: [],
      search: "",
      exportDialog: false,
      exportHeaders: [
        {
          title: "Name",
          propName: "forename",
          modifier: (forename, user) => {
            return `${forename} ${user.surname}`;
          }
        },
        {
          title: "Adresse",
          propName: "street",
          modifier: (street, user) => {
            return `${street} ${user.postalCode} ${user.city}`;
          }
        },
        {
          title: "Geburtstag",
          propName: "birthday",
          modifier: birthday => {
            return Parser.parseDate(new Date(birthday));
          }
        },
        {
          title: "Email",
          propName: "email",
          modifier: (email, user) => {
            return email || user.parentEmail || "";
          }
        },
        {
          title: "Telefon",
          propName: "phone1",
          modifier: (phone1, user) => {
            return Parser.parseTelefonNumber(phone1, user.phone2, user.phone3);
          }
        }
      ]
    };
  },
  computed: {
    allUsersRaw() {
      return this.$store.state.users.users.filter(user => {
        return (
          user.activities &&
          user.activities.find(
            activity =>
              activity.active && this.activityNames.includes(activity.name)
          )
        );
      });
    },
    allUsers() {
      return this.allUsersRaw.map(user => {
        let phoneString = Parser.parseTelefonNumber(
          user.phone1,
          user.phone2,
          user.phone3
        );
        let emailString = Parser.parseEmail(user.email, user.parentEmail);
        let birthdayString = Parser.parseDate(new Date(user.birthday));
        return {
          name: user.forename + " " + user.surname,
          address: user.street + " " + user.postalCode + " " + user.city,
          phone: phoneString,
          email: emailString,
          birthday: birthdayString,
          id: user.id
        };
      });
    },
    searchedUsers() {
      if (this.search === "") {
        return this.allUsers;
      }
      let splitted = this.search
        .split(" ")
        .map(string => string.toLowerCase())
        .filter(string => string);
      return this.allUsers.filter(user => {
        for (let search of splitted) {
          if (
            !(
              user.name.toLowerCase().includes(search) ||
              user.birthday.toLowerCase().includes(search)
            )
          ) {
            return false;
          }
        }
        return true;
      });
    }
  }
};
</script>

<style scoped></style>
