<template>
  <v-container fluid fill-height>
    <v-row class="justify-center mx-0">
      <v-card width="600" class="ma-3">
        <v-toolbar flat color="primary">
          <v-toolbar-title class="white--text">
            Passwort zurücksetzen
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          Um dein Passwort zurückzusetzten wird deine Account-Email benötigt. An
          diese wird in Kürze ein Link gesendet, mit welchem du ein neues
          Passwort wählen kannst.
          <v-form
            v-model="formValid"
            @submit.prevent="startResetPasswordProcess()"
            id="reset-password-form"
            lazy-validation
          >
            <v-text-field
              v-model="accountEmail"
              :rules="emailRules"
              prepend-icon="email"
              name="email"
              label="Account Email"
              type="text"
              required
            />
          </v-form>
          <v-alert v-if="info.display" border="left" type="success">
            {{ info.text }}
          </v-alert>
          <v-alert v-if="error.occured" border="left" type="error">
            {{ error.text }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="accountEmail === '' || !formValid"
            class="mr-1 mb-1"
            type="submit"
            color="primary"
            form="reset-password-form"
            >Email Senden</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import AccountAPI from "../../api/AccountAPI";

export default {
  name: "PasswordReset",
  data() {
    return {
      accountEmail: "",
      error: {
        occured: false,
        text: ""
      },
      info: {
        display: false,
        text: ""
      },
      formValid: false,
      emailRules: [
        v => !!v || "Account Email wird benötigt",
        v => /.+@.+\..+/.test(v) || "Bitte gültige Email eingeben"
      ]
    };
  },
  methods: {
    startResetPasswordProcess() {
      let vueI = this;
      AccountAPI.startResetPasswordProcess(this.accountEmail)
        .then(response => {
          vueI.error.occured = false;
          vueI.info = {
            display: true,
            text:
              "In den nächsten Minuten erhälst du den Link zum Zurücksetzen an deine Email gesendet. Dieser Link ist die nächsten 24 Stunden gültig."
          };
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          vueI.info.display = false;
          vueI.error = {
            occured: true,
            text: error.response.data
          };
        });
    }
  }
};
</script>

<style scoped></style>
