/* eslint-disable no-console */
import router from "../../router";
import AccountAPI from "../../api/AccountAPI";

const updateInterval = 30 * 1000; //30 seconds

export default {
  namespaced: true,

  state: {
    tokenPair: null,
    eventRegistrationToken: null,
    userInfo: {
      email: "",
      roles: [],
      emergencyContacts: []
    },
    userInfo_last_updated: 0
  },

  getters: {
    loggedIn: state => {
      return (
        state.tokenPair !== null &&
        // eslint-disable-next-line no-prototype-builtins
        state.tokenPair.hasOwnProperty("accessToken")
      );
    },
    isAdmin: state => {
      return state.userInfo && state.userInfo.roles.includes("Admin");
    },
    isWoelflingsleiter: state => {
      return state.userInfo && state.userInfo.roles.includes("Wölflingsleiter");
    },
    isJupfileiter: state => {
      return state.userInfo && state.userInfo.roles.includes("Jupfileiter");
    },
    isPfadileiter: state => {
      return state.userInfo && state.userInfo.roles.includes("Pfadileiter");
    },
    isRoverleiter: state => {
      return state.userInfo && state.userInfo.roles.includes("Roverleiter");
    },
    isStavo: state => {
      return state.userInfo && state.userInfo.roles.includes("StaVo");
    },
    isLeiter: state => {
      return (
        state.userInfo &&
        (state.userInfo.roles.includes("Wölflingsleiter") ||
          state.userInfo.roles.includes("Jupfileiter") ||
          state.userInfo.roles.includes("Pfadileiter") ||
          state.userInfo.roles.includes("Roverleiter"))
      );
    },
    isTreasurer: state => {
      return state.userInfo && state.userInfo.roles.includes("Kasse");
    },
    isNamiMember: state => {
      return state.userInfo && state.userInfo.namiId > 0;
    }
  },

  mutations: {
    setTokenPair(state, tokenPair) {
      state.tokenPair = tokenPair;
    },
    deleteTokenPair(state) {
      state.tokenPair = "";
    },
    deleteAccessToken(state) {
      state.tokenPair.accessToken = "";
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      if (userInfo.roles === null) {
        state.userInfo.roles = [];
      }
      state.userInfo_last_updated = Date.now();
    },
    deleteCurrentUser(state) {
      state.userInfo = {
        email: "",
        member: null,
        member_associated: false,
        name: "None",
        roles: [],
        emergencyContacts: []
      };
    },
    deleteAvatar(state) {
      state.userInfo.avatar_image_url = "";
    },
    newEmergencyContact(state, contact) {
      state.userInfo.emergencyContacts.push(contact);
    },
    updateEmergencyContact(state, payload) {
      let contacts = [...state.userInfo.emergencyContacts];
      let targetIndex = state.userInfo.emergencyContacts
        .map(contact => contact.id)
        .indexOf(payload.id);
      contacts[targetIndex] = payload;
      state.userInfo.emergencyContacts = contacts;
    },
    deleteEmergencyContact(state, id) {
      let targetIndex = state.userInfo.emergencyContacts
        .map(contact => contact.id)
        .indexOf(id);
      state.userInfo.emergencyContacts.splice(targetIndex, 1);
    },
    setEventRegistrationToken(state, token) {
      state.eventRegistrationToken = token;
    }
  },

  actions: {
    insertNewTokenPair({ commit }, payload) {
      let accessToken = payload.accessToken.tokenString;
      let refreshToken = payload.refreshToken.tokenString;

      var newTokenPair = {
        accessToken: accessToken,
        refreshToken: refreshToken
      };
      commit("setTokenPair", newTokenPair);
    },
    refreshUserInfo({ commit, state }) {
      if (
        !state.userInfo ||
        !state.userInfo_last_updated ||
        Date.now() - state.userInfo_last_updated > updateInterval
      ) {
        AccountAPI.getUserInfo()
          .then(userInfo => {
            commit("updateUserInfo", userInfo);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    insertUserInfo({ commit }, payload) {
      commit("updateUserInfo", payload);
    },
    doLogout({ commit }) {
      commit("deleteTokenPair");
      commit("deleteCurrentUser");
      localStorage.clear();
      router.push("landing");
    },
    newEmergencyContact({ commit }, payload) {
      AccountAPI.newEmergencyContact(payload)
        .then(data => {
          payload.id = data;
          commit("newEmergencyContact", payload);
        })
        .catch(error => {
          return error;
        });
    },
    updateEmergencyContact({ commit }, payload) {
      AccountAPI.updateEmergencyContact(payload)
        .then(() => {
          commit("updateEmergencyContact", payload);
        })
        .catch(error => {
          return error;
        });
    },
    deleteEmergencyContact({ commit }, id) {
      AccountAPI.deleteEmergencyContact(id)
        .then(() => {
          commit("deleteEmergencyContact", id);
        })
        .catch(error => {
          return error;
        });
    },
    deleteAvatar({ commit }) {
      //todo implement server side
      AccountAPI.deleteAvatar()
        .then(() => {
          commit("deleteAvatar");
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
