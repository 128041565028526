<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="timeout"
    :left="position.x === 'left'"
    :right="position.x === 'right'"
    :top="position.y === 'top'"
    :bottom="position.y === 'bottom'"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        :text="action.text !== undefined"
        :icon="action.icon !== undefined"
        v-for="(action, index) in actions"
        :key="index"
        v-bind="attrs"
        @click="callAndClose(action.function)"
      >
        <span v-if="action.text">{{ action.text }}</span>
        <v-icon v-if="action.icon">
          {{ action.icon }}
        </v-icon>
      </v-btn>
      <v-btn icon v-bind="attrs" @click="show = false">
        <v-icon>
          close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "GlobalSnackbar",
  data() {
    return {
      show: false,
      text: "",
      color: "success",
      timeout: "5000",
      position: {
        x: "",
        y: "bottom"
      },
      actions: []
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW_SNACK") {
        this.text = state.snackbar.text || "";
        this.color = state.snackbar.color || "success";
        this.timeout = state.snackbar.timeout || 5000;
        this.position = state.snackbar.position || {
          x: "",
          y: "bottom"
        };
        this.actions = state.snackbar.actions || [];
        this.show = true;
      }
    });
  },
  methods: {
    callAndClose(action) {
      action();
      this.show = false;
    }
  }
};
</script>

<style scoped></style>
