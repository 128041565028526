<template>
  <v-container fluid fill-height>
    <v-row class="justify-center mx-0">
      <v-card width="600">
        <v-toolbar flat color="primary">
          <v-toolbar-title class="white--text">
            Email Aktivierung
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert v-if="info.display" border="left" type="success">
            {{ info.text }}
          </v-alert>
          <v-alert v-if="error.occured" border="left" type="error">
            {{ error.text }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text to="/">
            Startseite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import AccountAPI from "../api/AccountAPI";
import { mapActions } from "vuex";

export default {
  name: "EmailActivation",
  data() {
    return {
      newEmail: "",
      error: {
        occured: false,
        text: ""
      },
      info: {
        display: false,
        text: ""
      }
    };
  },
  mounted() {
    let vueI = this;
    vueI.error.occured = false;
    vueI.info.display = false;
    AccountAPI.activateEmail(this.$route.params.token)
      .then(response => {
        vueI.info = {
          display: true,
          text:
            "Die Email " +
            response.data +
            " wurde erfolgreich aktiviert. In Zukunft musst du diese Email für den Login verwenden."
        };
        vueI.doLogout();
      })
      .catch(error => {
        console.log(error);
        if (error.response.data.includes("invalid token")) {
          error.response.data =
            "Leider ist dieser Link ungültig. Wiederhole bitte den Vorgang um deine Email zu ändern.";
        }
        vueI.error = {
          occured: true,
          text: error.response.data
        };
      });
  },
  methods: {
    ...mapActions("user", ["doLogout"])
  }
};
</script>

<style scoped></style>
