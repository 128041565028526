import axiosClient from "./AxiosConfig";

export default {
  async getReceiptCopyFile(file) {
    return axiosClient
      .get("/invoices/" + file, { responseType: "blob" })
      .then(response => {
        return new File([response.data], file, {
          type: response.headers["content-type"]
        });
      });
  },
  getReceiptCopyUrl(filename) {
    return process.env.VUE_APP_USER_API_BASE_URL + "/invoices/" + filename;
  },
  async getInvoices() {
    return axiosClient.get("/invoices").then(response => {
      return response.data;
    });
  },
  async submitInvoice(invoiceSubmission) {
    let formData = new FormData();

    let receiptCopyFileNames = [];
    invoiceSubmission.receiptCopy.forEach(copy => {
      console.log(copy);
      formData.append(copy.name, copy);
      receiptCopyFileNames.push(copy.name);
    });

    // destructing is used to update receiptCopy array without destroying stored files
    // eslint-disable-next-line no-unused-vars
    let { receiptCopy, ...invoiceSubmissionWithoutFiles } = invoiceSubmission;

    invoiceSubmissionWithoutFiles.receiptCopy = receiptCopyFileNames;
    let payloadString = JSON.stringify(invoiceSubmissionWithoutFiles);
    formData.append("invoice", payloadString);

    let params = {};
    if (invoiceSubmission.submitter && invoiceSubmission.submitter > 0) {
      params.submitter = invoiceSubmission.submitter;
    }
    console.log(params);

    return axiosClient
      .post("/invoices", formData, {
        headers: { "content-type": "multipart/form-data" },
        params
      })
      .then(response => {
        return response.data;
      });
  },
  async updateInvoice(updatedInvoice) {
    return axiosClient
      .put("/invoice/" + updatedInvoice.id, updatedInvoice)
      .then(response => {
        return response.data;
      });
  },
  async addInvoiceReceiptCopyParts(receiptCopyParts, invoiceId) {
    let formData = new FormData();

    let receiptCopyFileNames = [];
    receiptCopyParts.forEach(copy => {
      console.log(copy);
      formData.append(copy.name, copy);
      receiptCopyFileNames.push(copy.name);
    });

    formData.append(
      "newReceiptCopyParts",
      JSON.stringify({ receiptCopyParts: receiptCopyFileNames })
    );
    return axiosClient
      .put("/invoice/" + invoiceId + "/receiptcopy", formData, {
        headers: { "content-type": "multipart/form-data" }
      })
      .then(response => {
        return response.data;
      });
  },
  async deleteInvoiceReceiptCopyParts(receiptCopyParts, invoiceId) {
    return axiosClient
      .post("/invoice/" + invoiceId + "/receiptcopy/deletes", {
        receiptCopyParts: receiptCopyParts
      })
      .then(response => {
        return response.data;
      });
  },
  getAuditLogOfInvoice(invoiceId) {
    return axiosClient
      .get("/invoice/" + invoiceId + "/audit-log")
      .then(response => {
        return response.data;
      });
  },
  getTags() {
    return axiosClient.get("/tags").then(response => {
      return response.data;
    });
  },
  createTag(tagName) {
    return axiosClient.post("/tags", { name: tagName }).then(response => {
      return response.data;
    });
  },
  updateTag(updatedTag) {
    return axiosClient.put("/tag/" + updatedTag.id, updatedTag);
  },
  removeTag(tagId) {
    return axiosClient.delete("/tag/" + tagId);
  }
};
