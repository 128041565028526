<template>
  <div>
    <v-row class="justify-center mx-0">
      <div class="ml-1 mt-2 ma-md-4">
        <span class="headline">Gruppenstunden</span>
      </div>
    </v-row>
    <v-row class="justify-space-between px-md-2 mx-0">
      <v-col class="flex-grow-1 pa-1" v-if="hasPermission('Woelflingsleiter')">
        <stufen-list
          list-name="Wölflinge"
          :activity-names="['Wölfling', 'Wölflingsleiter']"
        />
      </v-col>
      <v-col class="flex-grow-1 pa-1" v-if="hasPermission('Jupfileiter')">
        <stufen-list
          list-name="Jupfis"
          :activity-names="['Jungpfadfinder', 'Jungpfadfinderleiter']"
        />
      </v-col>
      <v-col class="flex-grow-1 pa-1" v-if="hasPermission('Pfadileiter')">
        <stufen-list
          list-name="Pfadis"
          :activity-names="['Pfadfinder', 'Pfadfinderleiter']"
        />
      </v-col>
      <v-col class="flex-grow-1 pa-1" v-if="hasPermission('Roverleiter')">
        <stufen-list
          list-name="Rover"
          :activity-names="['Rover', 'Roverleiter']"
        />
      </v-col>
    </v-row>
    <v-row class="justify-center mx-0" v-if="hasPermission('Stavo')">
      <div class="ml-1 mt-2 ma-md-4">
        <span class="headline">Leiterteams</span>
      </div>
    </v-row>
    <v-row
      class="justify-space-between px-md-2 mx-0"
      v-if="hasPermission('StaVo')"
    >
      <v-col class="flex-grow-1 pa-1">
        <stufen-list
          list-name="Wölflingsleiter"
          :activity-names="['Wölflingsleiter']"
        />
      </v-col>
      <v-col class="flex-grow-1 pa-1" v-if="hasPermission('StaVo')">
        <stufen-list
          list-name="Jupfileiter"
          :activity-names="['Jungpfadfinderleiter']"
        />
      </v-col>
      <v-col class="flex-grow-1 pa-1" v-if="hasPermission('StaVo')">
        <stufen-list
          list-name="Pfadileiter"
          :activity-names="['Pfadfinderleiter']"
        />
      </v-col>
      <v-col class="flex-grow-1 pa-1" v-if="hasPermission('StaVo')">
        <stufen-list
          list-name="Roverleiter"
          :activity-names="['Roverleiter']"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StufenList from "../../components/user/StufenList";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "StavoDashboard",
  components: {
    "stufen-list": StufenList
  },
  computed: {
    ...mapGetters("user", [
      "isWoelflingsleiter",
      "isJupfileiter",
      "isPfadileiter",
      "isRoverleiter",
      "isStavo",
      "isAdmin"
    ])
  },
  methods: {
    ...mapActions("users", ["refreshUsers"]),
    hasPermission(name) {
      let permission = false;
      permission = permission || this.isStavo;
      permission = permission || this.isAdmin;
      if (this["is" + name]) {
        permission = permission || this["is" + name];
      }
      return permission;
    }
  },
  mounted() {
    this.refreshUsers();
  }
};
</script>
